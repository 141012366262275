import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setIsVisible(false);
  };

  const rejectCookies = () => {
    Cookies.set('cookieConsent', 'rejected', { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 flex flex-col md:flex-row justify-center"       style={{ zIndex: 9999 }}
>
      <div className="bg-gray-100 p-4 md:p-5 lg:p-8 flex-1">
        <p className="text-lg font-semibold mb-1">Our website uses cookies</p>
        <p className="text-gray-700 mb-1">
          This website collects cookies to deliver a better user experience.
        </p>
        <div className="flex items-center space-x-2 mt-[-5px] ">
          <Link to="/imprint" className="text-blue-600 hover:underline">Imprint</Link>
          <span className="text-gray-500">|</span>
          <Link to="/data-protection" className="text-blue-600 hover:underline">Data Protection Policy</Link>
        </div>
      </div>
      <div className="bg-white border border-gray-300 p-4 md:p-6 lg:p-5 flex items-center justify-center space-x-4 w-full md:w-[26rem] lg:w-[27rem] md:pr-16 lg:pr-16">
        <button
          onClick={acceptCookies}
          className="bg-[#2A60D6] text-white font-semibold py-2 px-6 rounded hover:bg-blue-700 transition-colors duration-200 w-28 md:w-32 lg:w-36"
        >
          Accept
        </button>
        <button
          onClick={rejectCookies}
          className="bg-red-600 text-white font-semibold py-2 px-6 rounded hover:bg-red-700 transition-colors duration-200 w-28 md:w-32 lg:w-36"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
