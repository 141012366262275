

const termsData = {
  mainHead:"Terms Of Use",
  termsOfUse: {
    title: "Terms Of Use",
    effectiveDate: "Effective Date: July 12, 2024",
    note: `Note: VISION MED / Second Opinions Oncology is not intended to address emergency or life-threatening medical conditions. If this is an emergency, please call 9110 immediately.`,
    sections: [
      {
        heading: "General",
        content: `These Terms and Conditions apply to the Internet offerings ("Second Opinion Oncology", platform) (<a class="underline" href="https://www.vision-med.eu" target="_blank">https://www.vision-med.eu</a>)
 and the Mobile App ("Second Opinion Oncology") provided by VISION MED GmbH. These Terms of Use apply to your access to the Second Opinion website (<a class="underline" href="https://www.vision-med.eu" target="_blank">https://www.vision-med.eu</a>) and the Mobile App (Android and iOS). These two digital services are operated by VISION MED GmbH. Please read these terms of use carefully and contact us if you have any questions. By accessing and using Second Opinion Oncology, you agree to be bound by these Terms of Use and our Privacy Policy.
        <br/><br/>
        The use of the services offered by Second Opinion Oncology is subject to these Terms of Use.
        <br/><br/>
        Provider of the Internet offer under: <a class="underline" href="https://www.vision-med.eu" target="_blank">www.vision-med.eu</a>
        <br/><br/>
        VISION MED GmbH
        <br/>
        Julius-Hatry-Str. 1
        <br/>
        68163 Mannheim
        <br/>
        Germany
        <br/>
        Managing Directors: Dr. Christian Schmitt, Uwe Zell, Konstantin Shcherbina
        <br/>
        Email: <a class="underline" href="mailto:support@vision-med.eu" target="_blank">support@vision-med.eu</a>
        <br/>
        VAT identification number according to § 27a German Value Added Tax Act: DE342112924
        <br/><br/>
        These Terms of Use exclusively apply to the use of the services provided by VISION MED GmbH as described in § 2 for consumers/patients (hereinafter also referred to as "Users").
        <br/><br/>
        We take your patient safety very seriously. Therefore, you can only use our services if you:
        <ul class="unordered">
        
          <li>are at least 18 years old,</li>
          <li>are legally competent</li>
        </ul>`
      },
      
      {
        heading: "Scope of Services",
        content: `
       <ol class="orderred">
    <li>Second Opinion Oncology is an online platform that enables licensed physicians in Germany to provide telemedicine consulting services in the field of oncology. Second Opinion Oncology itself is not a healthcare provider but merely a digital platform that facilitates the offering of medical services by established physicians. No medical prescriptions are issued. <br/><br/>After registration, patients can upload relevant medical findings via the app or web portal for review by the second opinion physician and book an appointment for a video or telephone consultation. In addition, patients have the option to document their complaints in a digital medical history form. The patient can choose the second opinion physician based on a photo, resume, and indicated specialization. The chosen physician will discuss the findings and provide their professional assessment (second opinion) to the patient via telephone or video conference (consultation) and subsequently create a medical report. A maximum of 5 working days is allowed between booking the appointment and the consultation. The medical report will be made available to the patient for download. If a consultation with other medical specialties is necessary for a comprehensive medical assessment and you have given your consent, the patient's case will be presented anonymously in an interdisciplinary tumor conference. <br/><br/>The physicians registered on the VISION MED Second Opinion Oncology platform ensure that the consultation provided solely via communication media only takes place if it is medically justifiable and that the required medical diligence is maintained, especially concerning the methods of obtaining findings, counseling, treatment, and documentation. They also inform the patient about the specifics of exclusive counseling and treatment via communication media. </li>

 
    <li>Second Opinion Oncology assumes the following tasks, in particular:
      <ul class="unordered">
        <li>Creation of a profile for patients/users. With the user's consent, transmission of the data required for service provision to the respective physicians and, if desired, storage of this data to facilitate follow-up consultations.</li>
        <li>Coordination of the video/telephone consultation</li>
        <li>Provision of the underlying technology platform for all processes</li>
      </ul>
    </li>

    <li>Special professional legal regulations and restrictions apply to the treating physicians for medical care provided over the Internet. Therefore, the range of services is limited to the current legal possibilities. Special notes in the service description and provision are legally binding.</li>

    <li>The treatment contract concluded between the patient and the physician is not part of Second Opinion Oncology's services. Second Opinion Oncology is not responsible for the content of this contract or any communication between the patient and the physician. Second Opinion Oncology does not offer any medical (service) performances. Instead, Second Opinion Oncology merely facilitates the treatment contact between patients and physicians within the framework of telemedicine treatment through its platform.</li>

    <li>Second Opinion Oncology does not deliver medications or participate in a treatment contract between the patient and the pharmacy.</li>

    <li>The patient books a treatment with a physician of their choice via the platform. All rights and obligations arising from the treatment contract exist exclusively between the patient and the treating physician. For the medical treatment, the patient initially purchases a treatment voucher (a redeemable value voucher for the treatment) through Second Opinion Oncology (VISION MED GmbH). The treatment voucher can only be purchased via the platform <a class="underline" href="https://www.vision-med.eu" target="_blank">(https://www.vision-med.eu)</a> and is redeemable for booked medical treatments via the platform and the Mobile App ("Second Opinion Oncology"). The treatment voucher covers the cost of the treatment provided by the chosen physician. Thus, the patient concludes a contract for the purchase of the value voucher with VISION MED GmbH and a separate treatment contract for the medical services with the respective treating physician.</li>
  </ol>`
      },  {
        heading: "Technical Requirements for Using the Second Opinion Mobile App (iOS and Android) and the Second Opinion Web Frontend",
        content: `
          <ol class="orderred">
            <li>To use the Second Opinion Oncology app on Apple devices, you need an iOS device with an operating system from version 10.0 or higher. For Android devices, a minimum operating system version of > 8.0 (SDK26) is required.</li>
            <li>To use the Second Opinion Oncology app on Android devices, you need an Android device with an operating system from version 7 (Marshmallow) or higher.</li>
            <li>The Second Opinion Oncology Web Version (<a class="underline" href="https://www.vision-med.eu" target="_blank">www.vision-med.eu</a>) supports the latest two versions of the following browsers: Chrome, Firefox, Safari, Opera, and Vivaldi.</li>
            <li>Second Opinion Oncology regularly provides app updates in the App Store or Play Store. To ensure flawless technical use, we recommend that users always keep the app up to date with the latest releases. Only by updating the app will users be able to access and utilize new features.</li>
          </ol>
        `
      }
,      
      {
        heading: "Registration",
        content: `
        The use of Second Opinion Oncology generally requires the creation of a free user-specific account (registration). Registration requires that the user truthfully and completely transmits all data requested in the registration form to Second Opinion Oncology. At the time of registration, the user must be at least 18 years old and fully legally competent. The user is prohibited from registering more than once.<br/><br/>
        There is no legal entitlement to the use of Second Opinion Oncology's services. Second Opinion Oncology reserves the right to refuse registration without giving any reason.<br/><br/>
        After the user (patient) has registered, they will receive an activation link to the provided email address, where they need to confirm their email address again. Once this is done, the user can utilize the services offered by Second Opinion Oncology. This process aims to prevent registration abuse. The user agreement with the patient only comes into effect after confirming the link sent via email.
      `
      
      },
      {
        heading: "Instruction on the Right of Withdrawal",
        subheading: "",
        content: `
        <br/>
        <strong>Right of Withdrawal</strong><br/><br/>
          You have the right to withdraw from this contract within fourteen days from the day of the conclusion of the contract (confirmation of your registration) without giving any reason.<br/><br/>
          To exercise your right of withdrawal, you must inform us, Second Opinion Oncology, by means of a clear statement (e.g. a letter sent by post or email) about your decision to withdraw from this contract.<br/><br/>
          To meet the withdrawal deadline, it is sufficient for you to send your communication concerning the exercise of the right of withdrawal before the withdrawal period has expired.<br/><br/>
          Your right of withdrawal will expire early if we have provided the service in full, and the execution of the service has only begun with your prior express consent and your confirmation that you are aware that you will lose your right of withdrawal once the contract is completely fulfilled by us.<br/><br/>
          You give your express consent by concluding the user agreement (registration and acceptance of these terms of use). Please note that this right of withdrawal only applies to the Second Opinion Oncology service and not to the separately concluded treatment contract with a physician and the services provided by the physician.<br/><br/>
          <strong>Consequences of Withdrawal</strong><br/><br/>
          If you withdraw from this contract, we will reimburse all payments we have received from you without undue delay and no later than fourteen days from the day we received your notice of withdrawal. For this repayment, we will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; in no event will you be charged any fees for such reimbursement. Second Opinion Oncology currently does not charge any usage fees for consumers. Fees arising from the physician's treatment contract are not included here.<br/><br/>
          <strong>Model Withdrawal Form</strong><br/><br/>
          If you want to withdraw from the contract, please fill out this form and send it back to us.<br/><br/>
          <strong>To:</strong><br/><br/>
          VISION MED GmbH<br/>
          Julius-Hatry-Str. 1<br/>
          68163 Mannheim<br/>
          Germany<br/><br/>
          Second Opinion Oncology Service<br/><br/>
          I/we () hereby withdraw from the contract concluded by me/us () for the provision of the following service<br/><br/>
          Ordered (registered) on ()/received (confirmation of registration) on ()<br/><br/>
          Name(s) of consumer(s)<br/><br/>
          Address(es) of consumer(s)<br/><br/>
          Signature(s) of consumer(s) (only if this form is notified on paper)<br/><br/>
          Date<br/><br/>
          (*) Delete as appropriate.<br/><br/>
           <ul class="unordered sub-list">
        <li> End of the instructions on the right of withdrawal</li>
       
      </ul>
         
        `
      }
,      
{
  heading: "Information Obligations",
  content: `
    There is no out-of-court complaint or appeals procedure available to Second Opinion Oncology.<br/><br/>
    All other information about Second Opinion Oncology, the services offered, and the process can be found on <a class="underline" href="https://www.vision-med.eu" target="_blank">www.vision-med.eu</a>. Regarding the technical steps for concluding the contract, please refer to § 3 of the Terms of Use. During registration, users can interrupt and correct their data entries at any time. The language available for concluding the contract is German only.
  `
}
,
{
  heading: "Usage Rights",
  content: `
    The user is only entitled to the rights granted under these terms of use regarding the Internet offerings.<br/><br/>
    The content, information, images, videos, and databases published on the online and app applications of Second Opinion Oncology are protected by copyright and are the property of or licensed to Second Opinion Oncology and VISION MED GmbH.<br/><br/>
    The content on the online and app applications may only be used or reproduced for personal and non-commercial purposes. Redistribution of the content is prohibited without the express consent of Second Opinion Oncology.
  `
}
,
{
  heading: "Data Protection and Security",
  content: `
    The security of our users and patients is important to us. While we strive to protect your data and your account, Second Opinion Oncology cannot guarantee that unauthorized third parties will not succeed in breaking through our security measures. We ask you to keep your password secure. Please notify us immediately at service[at]second-opinion.eu if you find that the security of your account has been compromised or accessed without authorization.<br/><br/>
    For detailed information on how we handle your data, please refer to our Privacy Policy, which you confirm you have read during the registration process.
  `
}
,
{
  heading: "Availability",
  content: `
    Our services are generally available 7 days a week, 24 hours a day. This does not include times when data backup, system maintenance, or program maintenance work is performed on the system or database or when there is another technical disruption. Second opinion physicians are obliged to offer a consultation within 5 working days of booking. The respective appointments are individually made available for selection by the physician in the web portal.
  `
}
,
{
  heading: "Liability",
  content: `
    <ul class="orderred">
      <li>
        Liability of Second Opinion Oncology (VISION MED GmbH) for damages, in particular due to delay, non-performance, defective performance, or tort, exists only in the event of a breach of essential contractual obligations, the fulfillment of which is essential for the proper execution of the contract and on the observance of which the contractual partner may regularly rely. Otherwise, the liability of Second Opinion Oncology is excluded unless there are mandatory legal provisions. The exclusion of liability does not apply to willful intent and gross negligence, as well as in cases of culpable injury to life, body, or health. This liability limitation also applies to the legal representatives and vicarious agents of Second Opinion Oncology.
      </li>
      <li>
        Second Opinion Oncology is only liable for foreseeable damages. Liability for indirect damages, in particular consequential damages, unforeseeable damages, or atypical damages, as well as loss of profit, is excluded. The same applies to the consequences of labor disputes, accidental damages, and force majeure.
      </li>
      <li>
        Second Opinion Oncology is not liable for damages arising from the contractual relationship between the user and the physician, i.e., the treatment contract.
      </li>
      <li>
        Regarding the expert advisor, newsletter, videos, and interviews provided by Second Opinion Oncology, claims for liability against Second Opinion Oncology and its authors as advisors are generally excluded for damages of a material or immaterial nature caused by the use or non-use of the information provided or by the use of incorrect or incomplete information, unless there is provable willful intent or gross negligence on the part of the author.
      </li>
      <li>
        The above limitations of liability apply to all contractual and non-contractual claims.
      </li>
      <li>
        Each physician at Second Opinion Oncology is a recognized specialist with a valid license to practice medicine. Each physician must have their own professional liability insurance, which covers remote consultations with foreign patients as an insured event. Second Opinion Oncology also has its own platform professional liability insurance. This insurance covers:
        <ul class="unordered sub-list">
          <li>All activities of our employed staff.</li>
          <li>Platform software errors that lead to user complaints, for example, if patient data is overlooked by the physician due to technical problems, resulting in the patient receiving incorrect treatment and suffering harm.</li>
          <li>Liability of the treating physicians or Second Opinion Oncology and its employees for patients using Second Opinion Oncology from abroad is excluded.</li>
        </ul>
      </li>
    </ul>
  `
}
,
{
  heading: "Obligations of the User",
  content: `
    The Second Opinion service can only be offered in full if certain rules of conduct, which the user must adhere to, are followed.
    
    <ol class="orderred">
      <li>
        The user may only use the services provided in a manner appropriate to their purpose. In particular, he will keep his username and password for access confidential, not pass them on, not tolerate or enable any knowledge thereof, and take the necessary measures to ensure confidentiality. In the event of misuse or loss of this information or any corresponding suspicion, he will immediately inform Second Opinion Oncology.
      </li>
      <li>
        The user is obliged to provide correct, complete, and truthful information necessary for the provision of the services.
      </li>
      <li>
        To fully book a doctor's appointment via Second Opinion Oncology Service, the patient undertakes to provide a minimum of information that is essential for the doctor to assess the matter. If this does not happen within 48 hours, the booked service with the respective doctor will be automatically canceled. The user will be digitally informed about this.
      </li>
      <li>
        If there is no improvement, deterioration, or other unusual symptoms in the state of health, the user will consult a doctor on-site.
      </li>
      <li>
        Second Opinion Oncology is not to be used in an emergency or for illnesses that require a physical examination. In these cases, it is necessary to consult a doctor on-site or, in urgent cases, to go to an emergency room.
      </li>
    </ol>

    By booking the service, the patient concludes a private medical treatment contract with the respective physician. The contracting partner (patient) is aware that he is personally obliged to pay for the treatment costs, regardless of his reimbursement claims against a health insurance company, benefits agency, or other third-party payers. The patient settles the incurred treatment costs by means of the purchased treatment voucher (see § 2, § 13).
  `
}
,
{
  heading: "Termination/ Suspension of Access",
  content: `
    Second Opinion Oncology reserves the right to withdraw or suspend access authorization for any reason after providing appropriate notice. We are entitled to terminate or suspend access immediately and without notification if there is a valid reason, such as a violation of our policies. Even after termination, the user is still bound by the relevant sections of these terms.<br/><br/>

    If the user revokes their data protection consent to the processing of health data within the framework of this user agreement, this will result in the termination and thus the deletion/blocking of access, as the services cannot be provided without the processing of this data.
  `
}
,
    {
  heading: "Prices & Payments",
  content: `
    <ul class="orderred">
      <li>The prices announced to the user at the time of the conclusion of the contract via <a class="underline" href="https://www.vision-med.eu" target="_blank">https://www.vision-med.eu</a> (in the logged-in area) shall apply. The use of Second Opinion Oncology is free of charge for the patient. However, Second Opinion Oncology reserves the right to introduce a fee for the use of the service to adapt to changing market conditions, significant changes in procurement costs, changes in sales tax, or procurement prices. Should Second Opinion Oncology decide to introduce such a fee, Second Opinion Oncology will inform you accordingly in text form in advance. In this case, the user is permitted to terminate the contract immediately.</li>
      <li>Exclusively private medical services are provided via the platform, which the treating physicians will invoice according to the German Scale of Medical Fees (GOÄ). Each user will receive a corresponding invoice from the physician after the telemedicine treatment. The invoice will be paid with the treatment voucher previously purchased from Second Opinion Oncology.</li>
      <li>The purchase of the treatment voucher via Second Opinion Oncology Services is always made in advance. Here, the user can choose to pay by credit card or PayPal. Each payment is confirmed and authorized by the user. Second Opinion Oncology reserves the right to specify the methods of payment at the time of the user's order without giving reasons.</li>
      <li>When paying with PayPal, you will be redirected to the website of the online provider PayPal during the ordering process. In order to pay the invoice amount via PayPal, you must be registered there or register first, identify yourself with your access data, and confirm the payment instruction to us. After placing an order for a service, we will ask PayPal to initiate the payment transaction. You will receive further information during the ordering process. The payment transaction will be carried out automatically by PayPal immediately afterward.</li>
      <li>Consent to the electronic transmission of the invoice: Invoices are sent exclusively in electronic form. These documents are generally equivalent to paper documents. The documents are encrypted to protect them from unauthorized viewing. However, we would like to point out that the transmission of data on the Internet always entails the risk that third parties might intercept, decrypt, and read the data. For further information, please refer to our privacy policy and the data protection consent declaration that you need to submit.</li>
    </ul>
  `
}
,
{
  heading: "Amendments",
  content: `
    Second Opinion Oncology has the right to amend these Terms of Use at any time with effect for the future, provided that this does not pose any significant disadvantages for the user (e.g. changes to features, changes in the registration process, shortening of the notice period, etc.) or if amendments become necessary due to changes in legislation, case law, or Second Opinion Oncology's economic circumstances.
    <br/><br/>
    Intended changes will be communicated to registered users by email to the last email address provided to Second Opinion Oncology. The respective amendment shall become effective if the respective user does not object within two weeks after the email has been sent. The timely dispatch of the objection shall be decisive for compliance with the two-week period.
    <br/><br/>
    If the user objects to the amendment within the two-week period, Second Opinion Oncology shall be entitled to extraordinarily terminate the contractual relationship in whole or in part without notice, without any claims arising for the user from this.
    <br/><br/>
    If the contractual relationship is continued after the effective objection of the user, the previous terms of use shall remain valid.
  `
}
,
{
  heading: "Rescheduling, Cancellation, or Missed Appointments",
  content: `
    <ul class="orderred">
      <li>
        Patients can currently only reschedule their video or telephone appointment in exceptional cases or contact the treating physician directly to arrange a postponement.
      </li>
      <li>
        Rescheduling is currently only possible a maximum of 48 hours before the scheduled appointment. For rescheduling with less than 48 hours' notice, the appointment cannot be guaranteed.
      </li>
    </ul>
  `
}
,
{
  heading: "Final Provisions",
  content: `
    <ul class="orderred">
      <li>
        Should individual provisions of these terms of use, including this provision, be or become invalid in whole or in part, the validity of the remaining regulations shall remain unaffected. In place of the invalid or missing provisions, the respective statutory regulations shall apply. This also applies if a supplementary gap becomes apparent retrospectively.
      </li>
      <li>
        The law of the Federal Republic of Germany shall apply exclusively, excluding the UN Sales Convention and international private law.
      </li>
    </ul>
  `
},

     ],
  },
  privacyPolicy: {
    title: "Privacy Policy",
    effectiveDate: "Effective Date: July 12, 2024",
    note: "Please read these terms carefully before using the site.",
    sections: [
      {
        heading: "Introduction",
        content: `www.vision-med.eu ("us," "we," or "www.vision-med.eu") is committed to respecting the privacy rights of our customers, visitors, and other users of the www.vision-med.eu services ("Services") provided via the www.vision-med.eu website (collectively, "Website"). We created this Website Privacy Policy ("Privacy Policy") to give you confidence as you visit and use the Website, and to demonstrate our commitment to fair information practices and the protection of privacy. This Privacy Policy is only applicable to the Website, and not to any other websites that you may be able to access from the Website or any website of www.vision-med.eu's partners, each of which may have data collection, storage, and use practices and policies that differ materially from this Privacy Policy. Your use of the Website is governed by this Privacy Policy and the Terms of Use above.`
      },
      {
        heading: "Traffic Data Collected",
        content: `We automatically track and collect the following categories of information when you visit our Website:<br/>
      <ul class="unordered">
      <li>(1) IP addresses;</li>
    <li>(2) domain servers;</li>
    <li>(3) types of computers accessing the Website;</li>
    <li>(4) types of web browsers used to access the Website;</li>
    <li>(5) referring source which may have sent you to the Website; and</li>
    <li>(6) other information associated with the interaction of your browser and the Website (collectively "Traffic Data").</li></ul>`
      },
      {
        heading: "Personal Information Collected",
        content: `In order for you to access certain areas of the Website, we may require you to provide us with certain information that personally identifies you ("Personal Information"). Personal Information includes the following categories of information:
   <ul class="unordered"><li> (1) Contact Data (such as your e-mail address, phone number and Secondopiniononcology.com password);
   <li> (2) Demographic Data (such as your gender, your date of birth and your zip code);</li>
    <li>(3) Insurance Data (such as your insurance carrier and insurance plan);</li>
    <li>(4) Medical Data (such as your previous doctors and dentists ("Providers") visited, your reason for visit and your date of visit, and other medical information you choose to share with us) and</li>
    <li>(5) Bank Data to perform payment transactions. If you communicate with us by, for example, e-mail or letter, any information provided in such communication may be collected as Personal Information.</li></ul>`
      },
      {
        heading: "Interactive and Installed Tools",
        content: `We also collect other information, some of which may be Personal Information that you voluntarily provide to us when you choose to use some of the Website's interactive tools and services ("Interactive Tools"), such as searching for Providers and appointments with them. We also collect information you provide voluntarily in free-form text boxes on the Website and through responses to surveys, questionnaires and the like.<br/>
        <br/>Some of the free Interactive Tools on our site ask you for health-related Personal Information. You may elect to use certain Services available through the Website that require you to download and install software on your computer ("Installed Tools"). The Installed Tools may collect and transmit information from your computer system solely relating to use of the Installed Tools and for the purpose of providing you the relevant Services, for example, by informing www.vision-med.eu when a user is logged on and available to receive update or alert notices.`
      },
      {
        heading: "Cookies",
        content: `"Cookies" are small computer files that are transferred to your computer's hard drive that contain information such as user ID, user preferences, lists of pages visited and activities conducted while browsing the Website. At your option, expense and responsibility, you may block cookies or delete cookies from your hard drive. However, by disabling cookies, you may not have access to the entire set of features of the Website.<br/><br/> 
        Generally, we use "cookies" to customize your experience on our Website and to store your password so you do not have to re-enter it each time you visit the Website.<br/><br/> We use Google Analytics to understand the usage of our service and improve the user experience. For more information on how Google Analytics collects and processes data, please visit this page: How Google uses data when you use our partners.`
      },
      {
        heading: "Information Provided on Behalf of Children",
        content: `www.vision-med.eu does not collect information from children under the age of 13 in accordance with the Children's Online Privacy Protection Act ("COPPA") as discussed below. If you are a parent or legal guardian of a minor child, you may, in compliance with the Terms of Use the Site or Services on behalf of such minor child. Any information that you provide while using the Site or Service on behalf of your minor child will be treated as Personal Information as otherwise provided herein.`
      },
      {
        heading: "Children's Online Privacy Protection Act",
        content: `COPPA severely restricts what information can be collected from children under the age of 13. For this reason, children under the age of 13 in the United States are prohibited from using the Website. The Website is not directed at children, and www.vision-med.eu does not knowingly collect any information from individuals under the age of 13. If we learn that we have received any information from an individual under the age of 13, we will use that information only to respond directly to that child (or a parent or legal guardian) to inform him or her that he or she cannot use the Services and subsequently we will delete that information from our own servers.`
      },
      {
        heading: "www.vision-med.eu's Use of Your Information",
        content: `We may use your Contact Data to send you information about decondopiniononcology.com or our products or Services, to contact you when necessary, including to remind you of upcoming or follow-up appointments, and in conjunction with your use of certain Interactive Tools. We may use your Demographic Data, your Traffic Data, your Insurance Data or your Medical Data to customize and tailor your experience on the Website, in emails and in other communications, displaying content that we think you might be interested in and according to your preferences. You agree that www.vision-med.eu may use Personal Information to allow your doctors to make appointments with other doctors on your behalf through the Services.`
      },
      {
        heading: "Sharing of Information",
        content: `We share certain categories of information we collect from you in the ways described in this Privacy Policy. We may share Demographic Data with advertisers and other third parties only on an aggregate (i.e., non-personally-identifiable) basis. We may also share your Contact Data, Demographic Data, Insurance Data and Medical Data with Providers you choose to schedule on our Website.<br/><br/> We also share Personal Information and Traffic Data with our business partners who assist us by performing core services (such as hosting, billing, fulfillment, or data storage and security) related to our operation of the Website and/or by making certain Interactive Tools available to our users. Those business partners shall be bound to uphold the same standards of security and confidentiality that we have promised to you in this Privacy Policy, and they will only use your Contact Data and other Personal Information to carry out their specific business obligations to www.vision-med.eu and to provide your requested medical care and services. <br/><br/>Last, we may transfer information about you to another company in connection with a merger, sale or acquisition by or of www.vision-med.eu. In this event, we will use reasonable efforts to notify you before information about you is transferred and becomes subject to a different privacy policy. www.vision-med.eu does not share, sell, rent or trade your Personal Information with any third parties for their promotional purposes.`
      },
      {
        heading: "User Choice",
        content: `You may choose not to provide us with any Personal Information. In such an event, you can still access and use much of the Website; however you will not be able to access and use those portions of the Website that require your Personal Information.`
      },
      {
        heading: "Confidentiality and Security",
        content: `Except as otherwise provided in this Privacy Policy, we will keep your Personal Information private and will not share it with third parties, unless we believe in good faith that disclosure of your Personal Information or any other information we collect about you is necessary to: (1) comply with a court order or other legal process; (2) protect the rights, property or safety of www.vision-med.eu or another party; (3) enforce our Terms of Use; or (4) respond to claims that any posting or other content violates the rights of third-parties.`
      },
      {
        heading: "Physicians",
        content: `Physicians, their employees, and their agents should be particularly aware of their obligations of patient confidentiality, including without limitation their obligations under the Health Insurance Portability and Accountability Act ("HIPAA"), both in communicating with www.vision-med.eu and in responding to a review of their services posted on our Website. vision-med.eu does not have, and will not accept, any obligations of confidentiality with respect to any communications other than those expressly stated in this Privacy Policy and vision-med.eu's Terms of Use.`
      },
      {
        heading: "Public Information",
        content: `Any information that you may reveal in a review posting or other online discussion or forum is intentionally open to the public and is not in any way private. You should think carefully before disclosing any personally identifiable information in any public forum. What you have written may be seen and/or collected by third parties and may be used by others in ways we are unable to control or predict.`
      },
      {
        heading: "Security",
        content: `The security of your Personal Information is important to us. We follow generally accepted industry standards to protect the Personal Information submitted to us, both during transmission and once we receive it. For example, when you enter sensitive information on our Website, we encrypt that information using secure socket layer technology (SSL).<br/><br/> Although we make good faith efforts to store Personal Information in a secure operating environment that is not open to the public, you should understand that there is no such thing as complete security, and we do not guarantee that there will be no unintended disclosures of your Personal Information. If we become aware that your Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, we will use reasonable efforts to notify you of the nature and extent of the disclosure (to the extent we know that information) as soon as reasonably possible and as permitted by law.`
      },
      {
        heading: "Lost or Stolen Information",
        content: `You must promptly notify us if your Contact Data is lost, stolen, or used without permission. In such an event, we will remove that Contact Data from your account and update our records accordingly.`
      },
      {
        heading: "Updates and Changes to Privacy Policy",
        content: `We reserve the right, at any time, to add to, change, update, or modify this Privacy Policy so please review it frequently. If we do, then we will notify you here, as well as by posting a notice on our Website and/or by e-mailing you, along with a description of any changes (material or otherwise) and, where appropriate, a link to the modified policy so that you can review it. In all cases, use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.`
      },
      {
        heading: "Controlling Your Personal Information",
        content: `As a registered user of the Website, you can modify some of the Personal Information you have included in your profile or change your username by logging in and accessing your account. Upon your request, www.vision-med.eu will use commercially reasonable efforts to delete your account and the Personal Information in your profile; however, it may be impossible to remove your account without some residual information being retained by www.vision-med.eu.`
      },
      {
        heading: "Links to Other Websites",
        content: `The Website contains links to third party websites to which www.vision-med.eu has no affiliation. www.vision-med.eu does not share your personal information with those websites and is not responsible for their privacy practices. Some web sites may have the look and feel of our Website. Please be aware that you may be on a different site and that this Privacy Policy only covers our Website. Should you decide to visit one of these third party websites, we suggest that you read its privacy policy.`
      },
      {
        heading: "Contacts",
        content: `If you have any comments, concerns or questions about this Privacy Policy, please contact us at support@vision-med.eu.`
      }




    ]
  }
};

export default termsData;
