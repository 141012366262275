const contactContent = {
    title: "Contact Us",
    address: "MAFINEX Technologiezentrum",
    subAddress:"Julius-Hatry-Str. 1, 68163 Mannheim, Germany",
    instructions1:
      "Tell us your questions about our service. We usually respond during 48 hours to your email address.",
    instructions2:
      "Unfortunately, we will not be able to assist with healthcare issues or other questions outside our platform.",
    placeholders: {
      email: "Type your email here",
      question: "Please describe your question for VISION MED"
    },
    errorMessages: {
      emailRequired: "Email is required",
      invalidEmail: "Invalid email address",
      questionRequired: "Question is required",
      questionLength: "Question must have length from 10 to 500 characters"
    },
    buttonText: "Submit"
  };
  
  export default contactContent;
  