import React, { useState, useEffect } from 'react';
import imprintData from './imprintData';
import Navbar from '../../Pages/NavBar';
import Footer from '../../Pages/Footer';
import useScrollToTop from '../../config/useScrollToTop';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Imprint = () => {
  useScrollToTop();
  const [currentSection, setCurrentSection] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isContentOpen, setIsContentOpen] = useState(false);

  const {
    title,
    serviceprovider,
    representative,
    email,
    web,
    companyRegisteredOffice,
    localcourt,
    companyRegisterNumber,
    vatnumber,
    disputeresolution,
  } = imprintData.serviceProvider;

  // Define sections
  const sections = [
    { title: 'serviceprovider', name: serviceprovider.name },
    { title: 'representative', name: representative.name },
    { title: 'email', name: email.name },
    { title: 'web', name: web.name },
    { title: 'companyRegisteredOffice', name: companyRegisteredOffice.name },
    { title: 'localcourt', name: localcourt.name },
    { title: 'companyRegisterNumber', name: companyRegisterNumber.name },
    { title: 'vatnumber', name: vatnumber.name },
    { title: 'disputeresolution', name: disputeresolution.name },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
  

    // Set currentSection based on whether the device is mobile
    if (isMobile) {
      setCurrentSection('');
    } else {
      setCurrentSection('serviceprovider');
    }
  }, [isMobile]);

  const handleSectionClick = (title, e) => {
    if (e) e.preventDefault();
    setCurrentSection(title);
    const sectionElement = document.getElementById(title);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if(!isMobile){
    let found = false;
    sections.forEach((section) => {
      if (found) return;
      const sectionElement = document.getElementById(section.title);
      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          setCurrentSection(section.title);
          found = true;
        }
      }
    });
  }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, [sections]);

  const toggleContent = () => {
    setIsContentOpen((prevState) => !prevState);
  };

  return (
    <>
      <Navbar />
      {isMobile ? <div className="bg-white p-2 px-6 shadow-md mt-4">
                <button
                  className="flex justify-between items-center w-full text-lg font-semibold text-[#0A1E54]"
                  onClick={toggleContent}
              >
                  <span>Contents</span>
              
                  {isContentOpen ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                
                {isContentOpen && (
                  <ul className="mt-4 space-y-2">
                    {sections.map((section) => (
                      <li key={section.title}>
                        <a
                          href={`#${section.title}`}
                          className={`block text-lg font-semibold cursor-pointer ${currentSection === section.title ? 'text-[#2757C0]' : 'text-[#0A1E54]'}`}
                          onClick={(e) => handleSectionClick(section.title, e)}
                        >
                          {section.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div> : null}
              <hr
          className='md:hidden'
          style={{
            borderTop: "1px solid rgba(42, 96, 214, 1)",
            width: "100%",
            margin: "auto",
          }}
        />
      
      <div className="bg-[#F7F9FF]">
        <div className="md:px-[5.850%]">
          <div className="flex flex-col md:flex-row">
            {/* Sidebar for Desktop */}
            <div className={`hidden md:flex ${isMobile ? 'md:hidden' : ''}`}>
              <div className="flex-1 py-6">
                <h1 className="text-[#2757C0] text-4xl md:text-[3.2rem] font-semibold mb-12 mt-12 text-justify md:ml-[-2.4px]">{title}</h1>
                <div className="md:py-[32%]" style={{ position: 'sticky', top: "100px", alignSelf: 'flex-start' }}>
                  <div className="text-[#0A1E54] font-semibold font-helvetica mb-6">
                    {sections.map((section) => (
                      <a
                        key={section.title}
                        href={`#${section.title}`}
                        className={`block mb-2 text-[1.10rem] ${currentSection === section.title ? 'font-bold text-[#2757C0]' : 'text-[#0A1E54]'}`}
                        onClick={(e) => handleSectionClick(section.title, e)}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile heading with background color */}
            <div className={`w-full ${isMobile ? 'bg-[#F7F9FF] py-10 px-5' : ''} md:hidden`}>
              <h1 className="text-[#2757C0] text-justify text-4xl md:text-[3.2rem] font-semibold ">
                {title}
              </h1>
              {/* Mobile Toggle for Content */}
       
            </div>

            {/* Content Section */}
            <div className={`flex-1 md:ml-8 md:py-[13%] py-5 md:mb-8 px-5 md:pl-[16%] md:pr-[1%] ${isMobile ? 'bg-white' : ''}`}>
              <div className="w-full">
                {sections.map((section) => (
                  <div key={section.title}>
                    <h2 id={section.title} className={`text-[2rem] text-[#2757C0] md:mt-8 mt-4 mb-2 mb:mb-4 font-semibold font-helvetica`}>
                      {section.name}
                    </h2>
                    <p className="text-lg  text-[#0A1E54] font-medium font-inter md:text-justify">
                      {imprintData.serviceProvider[section.title]?.subtext }
                    </p>
                  </div>
                ))}
                <div className="mb-8">
                  <p className="text-lg md:text-justify text-[#0A1E54] font-medium font-inter" dangerouslySetInnerHTML={{ __html: disputeresolution.subtexts }}></p>
                  <p className="text-lg md:text-justify text-[#0A1E54] font-medium mt-4 font-inter" dangerouslySetInnerHTML={{ __html: disputeresolution.additionalinfo }}></p>
                  <p className="text-lg md:text-justify text-[#0A1E54] font-medium mt-4 font-inter" dangerouslySetInnerHTML={{ __html: disputeresolution.content }}></p>
                  <p className="text-lg md:text-justify text-[#0A1E54] font-medium mt-4 font-inter" dangerouslySetInnerHTML={{ __html: disputeresolution.info }}></p>
                  <p className="text-lg md:text-justify text-[#0A1E54] font-medium font-inter" dangerouslySetInnerHTML={{ __html: disputeresolution.infosub }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Imprint;
