import DoctorImg from "../../assets/images/doctorimage.png";
import Bundesministerium from "../../assets/images/Bundesministerium.png";
import OnkologieBethanien from "../../assets/images/OnkologieBethanien.png";
import Iuvando from "../../assets/images/Iuvando.png";
import ArrowImg from "../../assets/images/Arrow.png";
import SectionImage from "../../assets/images/SectionImage.png";
import DoctorIcon from "../../assets/images/DoctorIcon2.png";
import TrustedIcon from "../../assets/images/TrustedIcon.png";
import ExpertiseIcon from "../../assets/images/ExpertiseIcon.png";
import EUIcon from "../../assets/images/EUIcon.png";
import DoctorSection from "./DoctorSection";
import UniversitaIcon from "../../assets/images/Universita.png";
import smileface from "../../assets/images/smileface.png";
import Doctor from "../../assets/images/DoctorIcon.png";
import MobileArrow from "../../assets/images/MobileArrow.png";
import Doct1 from "../../assets/images/doctor1.png";
import Doct2 from "../../assets/images/doctor2.png";
import HomeDoctor1 from "../../assets/images/HomepageDoctor1.png";
import HomeDoctor2 from "../../assets/images/HomepageDoctor2.jpeg";
import doctorlogo from "../../assets/images/doctorlogo.png";
import HomeDoctor3 from "../../assets/images/HomePageDoctor3.jpeg";
import ChristianS from "../../assets/images/ChristianS.png";
import Hens from "../../assets/images/HansTesch.png";
import HowItWork1 from "../../assets/images/platformWork1.png";
import HowItWork2 from "../../assets/images/platformWork2.png";
import HowItWork3 from "../../assets/images/platformWork3.png";
import HowItWork4 from "../../assets/images/platformWork4.png";
import HowItWork5 from "../../assets/images/platformWork5.png";


const steps = [
  {
    id: 1,
    img:HowItWork1,
    title: "Register at this website",
    time: "5 minutes",
  },
  {
    id: 2,
    img:HowItWork2,
    title: "Select your preferred cancer specialist online",
    time: "less than 30 minutes",
  },
  {
    id: 3,
    img:HowItWork3,
    title: "Select if you want only report or video call",
    time: "5 minutes",
  },
  {
    id: 4,
    img:HowItWork4,
    title: "Upload your medical documents",
    time: "20 minutes",
  },
  {
    id: 5,
    img:HowItWork5,
    title: "Get a response 5 days after request",
    time: "5 days",
  },
];

const features = [
  {
    icon: DoctorIcon,
    title: "Reputable Cancer doctors",
    description:
      "Doctors on our platform exercise at highly reputable German medical institutions such as the Frankfurt University Hospital and, Onkologie Bethanien medical clinic.",
  },
  {
    icon: TrustedIcon,
    title: "Trusted Company",
    description:
      "We are part of the network of companies associated with Life Science accelerator hubs in Germany. The company leadership team hails from the best business school in Germany.",
  },
  {
    icon: EUIcon,
    title: "EU-recognised",
    description:
      "We are alumni of the EIT Health med-tech bootcamp, a programme co-funded by the European Union.",
  },
  {
    icon: ExpertiseIcon,
    title: "Wide area of expertise",
    description:
      "We have top doctors specialized in Ear, Nose and Throat Cancer, Thoracic Oncology, Hematology, Breast Cancer, Gastrointestinal Oncology, Gynecological Cancers, Urological Oncology.",
  },
];

const sections = [
  {
    id: 1,
    title: "Get a second opinion without a foreign trip",
    description:
      "Our digital platform allows to get a medical report from a German doctor on oncology conditions just by applying online.",
    linkText: "Try the platform",
    linkUrl: "#",
    image: HomeDoctor3,
  },
  {
    id: 2,
    title: "Choose among highly-qualified oncologists",
    description:
      "We have gathered reputable doctors with wide range of specialty  to help patients with a second opinion. Save time when choosing a doctor by viewing their full information instantly.",
    linkText: "Find your doctor",
    linkUrl: "#",
    image: HomeDoctor2,
  },
  {
    id: 3,
    title: "Receive a second opinion in 5 days",
    description:
      "The specialist will send the report to your email. You can also have a video call discussion where the report will be presented. ",
    linkText: "Register",
    linkUrl: "#",
    image: HomeDoctor1,
  },
];
const expertise = [
  {
    icon: Doctor,
    title: "Ensure accurate diagnosis with trusted expertise",
    description: [
      "Submit your request easily through a secure online platform.",
      "Benefit from advanced medical evaluations from Germany.",
      `Receive detailed, personalized feedback on your initial diagnosis within <strong>5 days</strong>.`
    ]
  },
  {
    icon: smileface,
    title: "Plan your treatment with comprehensive guidance",
    description: [
      "Understand your diagnosis fully through an expert second opinion.",
      "Receive actionable recommendations and tailored treatment options.",
      "Stay informed to make better health-related decisions."
    ]
  }
];

 const doctors = [
  {
    name: "Prof. Dr. Hans Tesch",
      position: "Oncologist",
      specialties: `Hematology and oncology specialist
      <br/>
Main focus: breast cancer, palliative medicine<br/>
>10 years of medical experience`,
  
      experience:
        ">10 years of medical experience",
  
      image:Hens,
      logos: [OnkologieBethanien,doctorlogo],
  },
  {
    name: "Dr. Christian Schmitt, MD",
    position: "Oncologist",
    specialties:
      `Urological Oncology, Head - Neck Cancers, Hematology, Gastro–intestinal Oncology, Gynecological Cancers<br/>
      >10 years of medical experience`,
     experience: ">10 years of medical experience",
    image:ChristianS,
      logos: [OnkologieBethanien,UniversitaIcon]
  }

  

  // {
  //   name: "Prof. Dr. Hans Tesch",
  //   position: "Oncologist",
  //   specialties: "Hematology, Gynecological Cancers",

  //   experience:
  //     ">10 years of clinical experience",

  //   image:
  //     "https://so-public.s3.eu-central-1.amazonaws.com/618256035cbdf3001e276314/attachment/2282ff51-b97c-4699-bef5-feab3f4da1c3.jpg",
  //   logos: [OnkologieBethanien],
  // },
];

const partners = [
  {
    name: "Federal Ministry for Economic Affairs and Climate Action",
    logo: Bundesministerium,
    description:
      "The Federal Ministry for Economic Affairs and Climate Action is a supreme federal authority of the Federal Republic of Germany. Its main headquarters or primary office is located in Berlin. Climate protection, digitalization, demographic change, securing energy supply, and expanding clean energy generation, particularly from wind and solar – the challenges of the 21st century present us with significant tasks and simultaneously offer new opportunities.",
  },
  {
    name: "Onkologie Bethanien",
    logo: OnkologieBethanien,
    description:
      `The Centrum Hämatologie und Onkologie Bethanien has been caring for people with cancer for over 30 years. It is our mission to provide individual, compassionate, and top-level medical care to our patients.<br/>
In our center, we can treat patients on an outpatient basis as well as, if necessary, on an inpatient basis at the AGAPLESION Bethanien Hospital. We thus offer comprehensive care with the most modern diagnostic and therapeutic options.`,
  },
  {
    name: "iuvando Health",
    logo: Iuvando,
    description:
      "The iuvando Health GmbH is a leading healthcare company specializing in the development of innovative solutions for the healthcare sector. iuvando Health offers state-of-the-art technologies and services that contribute to improving healthcare. Their vision is to promote and develop emerging healthcare technologies that improve people's quality of life.",
  },
];
const pageBasicContent= {
title:"Get a second opinion from a highly-qualified oncologist",
info:`In just <strong>5 days</strong>, use expertise from a German doctor to clarify the diagnosis and get a treatment plan.`,
btnText:"Find a doctor",
};
const sectionsContent={
  title:"How it works",
  info:`Ear, Nose and Throat Cancer, Thoracic Oncology, Hematology,
                  Breast Cancer, Gastrointestinal Oncology, Gynecological
                  Cancers, Urological Oncology.`,
                  subHead:"Conditions our specialists consult with:",
                  subInfo:"This way we minimize delays that impact the treatment prognosis.",
                  btnText:"Register",
                  btnText2:"Get a second opinion",
                  whyChooseUs:" Why choose us",
                  whyChooseUsBtn :"Find your doctor",
                  faq:"Questions Answered",
                  link:"Go to all questions",
                  regBtn:"Begin registration",
                  formHeading:"Let's keep in touch",
                  formLink:"Sign up to become a part of the community",
                  formBtn:"Submit",
                  doctorSection:"Take a look at our doctors",
                  partnerSection:" Partners who trust us",
                  packages:"It costs 10 times less than a traditional second opinion"

}

export {steps,features,sections,expertise,doctors,partners,pageBasicContent,sectionsContent};