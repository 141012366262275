import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import termsData from './TermsData';
import Navbar from '../../Pages/NavBar';
import Footer from '../../Pages/Footer';
import useScrollToTop from '../../config/useScrollToTop';

const TermsPage = () => {
  useScrollToTop();
  const { termsOfUse, privacyPolicy } = termsData;
  const [currentSection, setCurrentSection] = useState(null);
  const [isTermsNavVisible, setIsTermsNavVisible] = useState(true);
  const [isPrivacyNavVisible, setIsPrivacyNavVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const lastClickTime = useRef(0); // Ref to track the last click time

  const offset = 80;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set currentSection based on whether the device is mobile
    if (isMobile) {
      setCurrentSection('');
    } else {
      setCurrentSection(termsOfUse.sections[0].heading);
    }
  }, [isMobile]);

  const handleSectionClick = (heading, e) => {
    if (e) e.preventDefault();

    // Update the last click time to give click actions priority
    lastClickTime.current = Date.now();
    setCurrentSection(heading);

    const sectionElement = document.getElementById(heading.replace(/ /g, '-'));
    if (sectionElement) {
      const elementPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleSubheadingClick = (subheading, e) => {
    if (e) e.preventDefault();

    // Update the last click time to give click actions priority
    lastClickTime.current = Date.now();
    setCurrentSection(subheading);

    const subheadingElement = document.getElementById(subheading.replace(/ /g, '-'));
    if (subheadingElement) {
      const elementPosition = subheadingElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    // Check if enough time has passed since the last click to allow scroll updates
    if (!isMobile && Date.now() - lastClickTime.current > 800) {
      let found = false;

      [...termsOfUse.sections, ...privacyPolicy.sections].forEach((section) => {
        if (found) return;

        // Check the heading first
        const sectionElement = document.getElementById(section.heading.replace(/ /g, '-'));
        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
            setCurrentSection(section.heading);
            found = true;
          }
        }

        // Check subheadings only if the heading was not found
        if (!found && section.subheadings) {
          section.subheadings.forEach((subheading) => {
            if (found) return;

            const subheadingElement = document.getElementById(subheading.replace(/ /g, '-'));
            if (subheadingElement) {
              const subRect = subheadingElement.getBoundingClientRect();
              if (subRect.top >= 0 && subRect.top <= window.innerHeight / 2) {
                setCurrentSection(subheading); // Set the subheading as the current section
                found = true;
              }
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const toggleMobileNav = () => {
    setIsMobileNavOpen((prevState) => !prevState);
  };

  return (
    <>
      <Navbar />
      {/* Mobile Navigation */}
      {isMobile && (
        <>
        <div className="md:hidden w-full">
          <div className="bg-white p-2 px-4">
            <button
              className="flex justify-between items-center w-full text-lg font-semibold text-[#0A1E54]"
              onClick={toggleMobileNav}
            >
              <span>Contents</span>
              {isMobileNavOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isMobileNavOpen && (
              <ul className="mt-4 space-y-2">
                {termsOfUse.sections.map((section, index) => (
                  <li key={index}>
                    <a
                      href={`#${section.heading.replace(/ /g, '-')}`}
                      className={`block text-lg font-semibold cursor-pointer ${
                        currentSection === section.heading ? 'text-[#2757C0]' : 'text-[#0A1E54]'
                      }`}
                      onClick={(e) => handleSectionClick(section.heading, e)}
                    >
                      {section.heading}
                    </a>
                    {section?.subheading && (
                      <a
                        href={`#${section.subheading.replace(/ /g, '-')}`}
                        className={`block text-lg font-semibold cursor-pointer mt-2 ${
                          currentSection === section.subheading ? 'text-[#2757C0]' : 'text-[#0A1E54]'
                        }`}
                        onClick={(e) => handleSubheadingClick(section.subheading, e)}
                      >
                        {section.subheading}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
            <hr
            className='md:hidden'
            style={{
              borderTop: "1px solid rgba(42, 96, 214, 1)",
              width: "100%",
              margin: "auto",
            }}
          />
          </>
      )}

      <div className="bg-[#F7F9FF] md:pl-[6%] md:pr-[5%] md:pt-20 pt-14">
        <div className="md:max-w-[100%]">
          <h1
            className={`text-[#2757C0] px-5 md:px-0 text-4xl md:text-[3.2rem] font-semibold md:mb-20 mb-10 font-helvetica text-justify md:bg-transparent bg-[#F7F9FF] p-2' rounded-lg`}
          >
            {termsData?.mainHead}
          </h1>

          <p className="text-lg text-[#0A1E54] font-medium font-inter text-justify hidden md:block">
            {termsOfUse.effectiveDate}
          </p>
          <p className="text-[#0A1E54] font-medium mt-4 md:py-4 rounded mb-8 w-full hidden md:block md:w-[63%] font-inter text-[1.10rem] text-justify">
            {termsOfUse.note}
          </p>

          <div className="flex flex-col md:flex-row gap-8">
            {/* Sidebar for Desktop */}
            <div className="w-full md:w-[42rem] hidden md:block">
              <nav className="sticky top-20 max-h-[58vh] overflow-y-auto pr-2 custom-scrollbar md:mb-10">
                <div className="mb-8">
                  <div className="flex justify-between items-center">
                    <h2 className="text-[#0A1E54] font-semibold text-justify text-[26px] leading-[48px] tracking-[-0.30px] flex items-center font-helvetica mb-[5%]">
                      {termsOfUse.title}
                      <button
                        onClick={() => setIsTermsNavVisible(!isTermsNavVisible)}
                        className="text-[#0A1E54] pl-2 text-[25px] focus:outline-none"
                      >
                        {isTermsNavVisible ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                    </h2>
                  </div>
                  {isTermsNavVisible && (
                    <ul className="text-[#2757C0] list-none pl-0">
                      {termsOfUse.sections.map((section, index) => (
                        <li key={index} className="mb-2">
                          <a
                            href={`#${section.heading.replace(/ /g, '-')}`}
                            className={`text-[1.10rem] block text-justify font-helvetica font-semibold ${
                              currentSection === section.heading ? 'text-[#2757C0] mb-3' : 'text-[#0A1E54] mb-3'
                            }`}
                            onClick={(e) => handleSectionClick(section.heading, e)}
                          >
                            {section.heading}
                          </a>
                          {section?.subheading && (
                            <>
                            
                              <a
                                href={`#${section.subheading.replace(/ /g, '-')}`}
                                className={`text-[1.10rem] block text-justify font-helvetica font-semibold ${
                                  currentSection === section.subheading ? 'text-[#2757C0] mb-3' : 'text-[#0A1E54] mb-3'
                                }`}
                                onClick={(e) => handleSubheadingClick(section.subheading, e)}
                              >
                                {section.subheading}
                              </a>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </nav>
            </div>

            <div
              className={`w-full ${
                isTermsNavVisible || isPrivacyNavVisible ? 'md:w-full md:ml-[13%] px-5 py-5' : 'md:ml-[13%]'
              } ${isMobile ? 'bg-white' : ''}`}
            >
              {termsOfUse.sections.map((section, index) => (
                <div key={index} className="mb-14">
                  <h2
                    className="text-[2rem] font-semibold text-[#2757C0] mb-4 font-helvetica md:text-justify"
                    id={section.heading.replace(/ /g, '-')}
                    dangerouslySetInnerHTML={{ __html: section.heading }}
                  ></h2>

                  {section?.subheading && (
                    <><br/><br/>
                    <h2
                      className="text-[2rem] font-semibold text-[#2757C0] mb-4 font-helvetica md:text-justify"
                      id={section.subheading.replace(/ /g, '-')}
                      dangerouslySetInnerHTML={{ __html: section.subheading }}
                    ></h2></>
                  )}
                  <div
                    className="text-lg text-[#0A1E54] mb-3 font-inter md:text-justify"
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <style jsx>{`
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #00103C #F7F9FF;
          }
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(0, 16, 60, 0.7); /* Dark blue with slight transparency */
            border-radius: 60px !important;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #F7F9FF; /* Light background color */
          }
          .unordered {
            list-style-type: none;
            padding-left: 0;
          }
          .unordered li {
            position: relative;
            padding-left: 1.5rem; /* Adjust spacing between bullet and text */
            margin-bottom: 0.05rem; /* Adjust spacing between items */
          }
          .unordered li::before {
            content: '';
            position: absolute;
            top: 0.75rem; /* Adjust vertical alignment */
            left: 0;
            width: 0.4rem; /* Adjust size of bullet */
            height: 0.4rem; /* Adjust size of bullet */
            background-color: #0A1E54; /* Adjust color of bullet */
            border-radius: 50%;
          }
          .sub-list ul {
            margin-left: 2rem;
          }
          .sub-list li {
            margin-left: 1rem;
            color: #0A1E54 !important;
            font-size: 1.125rem;
            margin-bottom: 1rem;
            font-family: 'Inter', sans-serif;
            text-align: justify;
          }
          .orderred {
            margin-top: 15px;
            margin-bottom: 10px;
          }
          .orderred li {
            margin-top: 15px;
            margin-bottom: 10px;
          }
          .textstyling {
            font-weight: 500 !important;
            margin-top: 10px !important;
          }
          @media (max-width: 768px) {
            .sub-list ul {
              margin-left: 0rem;
            }
            .sub-list li {
              margin-left: 0rem;
              color: #0A1E54 !important;
              font-size: 1.125rem;
              margin-bottom: 1rem;
              font-family: 'Inter', sans-serif;
              text-align: justify;
            }
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};

export default TermsPage;
