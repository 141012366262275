import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  createRequestOptions,
  createSubscribeNewsletterRequestOptions,
} from "../../config/config";
import noImg from "../../assets/images/no-img.jpg";
import Loader from "../../Pages/Loader";
import { toast, ToastContainer } from "react-toastify";

const BlogPage = () => {
  useScrollToTop();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [articles, setArticles] = useState([]);
  const [featuredArticle, setFeaturedArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formloading, setFormLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [allArticle, setAllArticle] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const requestOptions = createRequestOptions("POST");
        const url = `${BASE_URL}/blog/list`;

        const response = await fetch(url, requestOptions);
        const result = await response.json();
        if (result.statusCode === 200) {
          const articlesData = result.data;
          setAllArticle(articlesData);
          const featured = articlesData.find(
            (article) => article.isFeatured === 1
          );
          setArticles(articlesData.filter((article) => article !== featured));
          setFeaturedArticle(featured);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const formattedEmail = email.toLowerCase();
    if (!validateEmail(formattedEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    const requestBody = {
      email: formattedEmail,
    };

    try {
      setFormLoading(true);
      const response = await fetch(
        `${BASE_URL}/send-newsletter-email-to-admin`,
        createSubscribeNewsletterRequestOptions(requestBody)
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "Success") {
        toast.success("Email submitted successfully!");
        setEmail("");
      } else {
        toast.error("Submission failed, please try again.");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error("There was a problem with the submission.");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
     <style>
  {`
    /* Default desktop view (up to 1400px) */
    .featured-article {
      display: grid;
      grid-template-columns: 31% 65%; /* Default layout */
      gap: 3rem; /* Adjust default gap */
    }

    /* For screens larger than 1400px */
   @media (min-width: 1640px) {
  .featured-article {
    grid-template-columns: 28% 58%; /* Adjusted layout for larger screens */
    gap: 2rem; /* Adjust the gap for larger screens */
  }
}

    /* Adjust for iPad and smaller screens */
    @media (max-width: 1024px) {
    
      .featured-article {
      grid-template-columns: 31% 59%;
        grid-template-columns: 1fr; /* Single column layout for iPad and mobile */
        gap: 1rem;
      }

      .mobile-img {
        width: 100%;
        height: auto;
      }
    }

    /* Mobile-specific styles */
    @media (max-width: 767px) {
      .featured-article {
    
      gap: 0.5rem; /* Adjust default gap */
    }
      .mobile-img {
        width: 100%; /* Full width image for mobile */
        height: auto; /* Maintain aspect ratio */
      }
    }
  `}
</style>
      <Navbar />
      <div className="bg-transparent md:bg-[#F7F9FF] md:px-[5.9%] md:py-16">

        <div className="md:px-0 md:py-0 ">
          <div className="bg-[#F7F9FF]   md:px-0 md:pt-0 md:pb-0 px-4 pt-10 pb-2">
          <h1
            className="leading-tight tracking-tight text-4xl font-semibold text-[#2757C0] md:text-[3.2rem]  mb-3  font-helvetica"
            style={{ position: "relative", zIndex: 20 }}
          >
            Helpful Articles for You
          </h1>
         
          <p className="text-[#0A1E54] md:text-lg text-[18px] font-medium font-helvetica mb-10 leading-relaxed">
            Learn more about how to...
          </p>
</div>


<div >
        <div className="md:pt-0 md:pb-0 px-4 pt-6 pb-4  md:px-0">
          {loading ? (
            <div className="w-full h-[500px] flex items-center justify-center">
              <Loader />
            </div>
          ) : allArticle?.length > 0 ? (
            <>
              {featuredArticle && (
                <>
                <div className="featured-article  md:mb-4 mb-2">
                <div className="flex justify-center md:justify-start md:h-[370px] md:w-[370px] md:max-h-[370px]  w-full h-auto max-h-[240px]  ">

  <img
  src={featuredArticle.imgUrl || noImg}
  alt={featuredArticle.title.en}
  className="
    rounded-lg 
  
    object-cover shadow-lg cursor-pointer"
  onClick={() =>
    navigate(`/blogarticle/${featuredArticle._id}`, {
      state: {
        id: featuredArticle._id,
        allArticle: allArticle,
      },
    })
  }
/>

  </div>

  <div className="flex flex-col justify-center  mt-2">
    <h3
      className="text-[#2757C0] cursor-pointer md:text-[2.65rem] text-4xl font-semibold tracking-normal font-helvetica leading-tight"
      onClick={() =>
        navigate(`/blogarticle/${featuredArticle._id}`, {
          state: {
            id: featuredArticle._id,
            allArticle: allArticle,
          },
        })
      }
    >
      {featuredArticle.title.en}
    </h3>
    <p className="text-[#192C5F] mt-2 md:text-justify md:text-[17.30px] text-[18px] font-medium font-helvetica w-auto md:max-w-[75%] leading-normal">
      {featuredArticle.overview.en}
    </p>
  </div>
</div>
                </>
              )}

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-[6rem] gap-16 md:mt-16 mt-16">
                {articles?.map((article) => (
                  <div key={article._id} className="flex flex-col md:items-start md:mt-8 sm:mt-8">
                    <img
                      src={article.imgUrl || noImg}
                      alt={article.title.en}
                      onClick={() => {
                        navigate(`/blogarticle/${article._id}`, {
                          state: {
                            id: article._id,
                            allArticle: allArticle,
                          },
                        });
                      }}
                      
                      className="rounded-lg w-full h-auto max-h-[240px] md:w-[180px] md:h-[180px] object-cover shadow-lg cursor-pointer"
                       />
                    <h3
                      onClick={() => {
                        navigate(`/blogarticle/${article._id}`, {
                          state: {
                            id: article._id,
                            allArticle: allArticle,
                          },
                        });
                      }}
                  
                      className="text-[#2757C0] cursor-pointer  md:text-[1.600rem] mt-3 text-4xl md:font-semibold font-semibold tracking-normal mb-2 font-helvetica leading-tight"
                    >
                      {article.title.en}
                    </h3>
                    <p className="text-[#192C5F] mt-2 md:text-justify md:text-[17.30px]  text-[18px] font-medium font-helvetica w-auto leading-normal">
                    {article.overview.en}
                      </p>
                   
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="w-full h-[500px] flex items-center justify-center">
              <p className="text-[#192C5F] text-[1.5rem]">No blogs found</p>
            </div>
          )}
        </div>
</div></div>
        {isMobile && (
          <div className="w-full bg-[#EBF0FF] md:mt-6 mt-2">
            <div className="w-full  py-10 flex flex-col items-left">
              <h2 className="text-[#2757C0] px-4 text-4xl font-semibold text-left tracking-tight leading-tight mb-6">
                Let’s keep in touch
              </h2>
              <p className="text-[#192C5F] px-4 mb-6 text-left font-semibold text-[17.50px]">
                Sign up to become a part of the community
              </p>
              <form
                className="w-full flex items-center px-3 gap-2"
                onSubmit={handleSubmit}
              >
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="jane@janesdomain.net"
                  className="flex-1 md:px-3 px-2 py-3 border rounded-lg w-[240px] text-[#192C5F] text-lg"
                />
                <button
                  type="submit"
                  className="py-3 md:px-5 px-4 bg-[#2757C0] text-white text-lg font-medium rounded-lg"
                >
                  {formloading ? <Loader /> : "Submit"}
                </button>
              </form>
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default BlogPage;
