const faqs = {
  General:[
    { question: "My medical reports are in a language other than English or German. Can I still book a second opinion?", answer: "Yes, VISION MED offers an intuitive platform that allows you to upload documents in any language. You can either provide your own translated version or utilize VISION MED's translation service to ensure your records are accessible to the German medical experts." },
    { question: "Can I upload all the information I have, including lab results and doctor's notes, for the second opinion?", answer: "Yes, you are encouraged to upload all relevant medical documents and information. This can include lab results, imaging reports, doctor's notes, and any other records that may help the German medical expert conducting your second opinion review." },
    { question: "Can I choose the doctor I want for my second opinion?", answer: "Yes, VISION MED's platform allows you to browse a list of specialized doctors and select your preferred cancer specialist. You can choose a doctor based on their expertise and your specific needs." },
    { question: "What if I need to cancel or reschedule my video consultation due to a last-minute conflict?", answer: "You can cancel or reschedule your video consultation in advance through the VISION MED platform. It is important to provide adequate notice to ensure efficient use of the doctor's time and yours." },
    { question: "How does the process work?", answer: "The process is simple and convenient. After booking a digital second opinion on our platform, we will connect you with a top oncology specialist who will review your medical records and provide a comprehensive second opinion. The entire process is done remotely, so you can get the peace of mind you need without having to travel." },
    { question: "Who are the specialists on your platform?", answer: "Our platform features a network of highly experienced and renowned oncology specialists from around the world. These experts have been carefully selected based on their expertise, qualifications, and commitment to providing exceptional patient care. You can rest assured that you are receiving a second opinion from a trusted and reputable specialist." },
    { question: "What information do I need to provide for the second opinion?", answer: "To ensure a thorough and accurate assessment, we will ask you to provide your complete medical records, including test results, scans, and any relevant medical history. Our specialists may also request additional information or clarification if needed. It is important that you provide as much detailed information as possible to enable a comprehensive evaluation." },
    { question: "How soon can I expect to receive the second opinion?", answer: "Our specialists understand the importance of timely assessments, and they will provide their second opinion within a specified timeframe, usually within a few days to a week. This ensures that you receive the guidance and answers you need without unnecessary delay. The exact turnaround time may vary depending on the complexity of your case and the specialist's availability." },
    { question: "What format will the second opinion be delivered in?", answer: "The second opinion will be delivered to you in a detailed written report, which will include the specialist's assessment of your medical records, their recommendations, and any suggested treatment options. Our specialists aim to provide clear and concise information, explaining any medical terminology, so you have a thorough understanding of your diagnosis and potential next steps." },
    { question: "Will I be able to ask questions or seek clarification after receiving the second opinion?", answer: "Absolutely! We understand that you may have questions or concerns after receiving the second opinion. Our platform offers ongoing support, allowing you to seek clarification or ask additional questions from the specialist for a certain period after receiving your second opinion. We want to ensure that you feel informed and empowered throughout your healthcare journey." }
  ],
  Patient: [
    { 
      question: "As a patient, how can Second Opinion help me?", 
      answer: "Second Opinion is a platform that connects you directly to top cancer specialists, providing speedy medical feedback and removing the complexity and financial burden of seeking a second opinion." 
    },
    { 
      question: "How is this different from the current process?", 
      answer: "The current process involves finding the right doctor, waiting for a response, and then organizing travel to Germany, which can take up to three months. Second Opinion eliminates these delays and provides an affordable alternative, with fees starting at €200." 
    },
    { 
      question: "What about choosing my doctor and language barriers?", 
      answer: "You can browse and select your preferred cancer specialist on the platform, where you can see the languages each medical specialist speaks. If you need a translation service, please get in touch with us." 
    },
    { 
      question: "How long does the whole process take?", 
      answer: "After submitting all relevant medical documents, you will receive your medical report within five days. There are no delays that could impact your cancer prognosis." 
    },
    { 
      question: "Are there mandatory fields and data that need to be provided to the medical expert?", 
      answer: "Yes, there is mandatory medical information that must be provided to the medical expert to enable them to start their work. You will be guided through the process to provide this mandatory information." 
    },
    { 
      question: "How can I be sure that the platform has qualified medical doctors onboard?", 
      answer: "We ensure the quality of medical doctors on the platform through rigorous checks. Each doctor must meet specific internal quality criteria to be onboarded." 
    },
    { 
      question: "How do I register as a patient on the Second Opinion platform?", 
      answer: "Simply go to our web portal, click on registration, and select 'Patient'. Enter your information and confirm the registration email." 
    },
    { 
      question: "How do I book a service as a patient on the Second Opinion platform?", 
      answer: "Log into the app or the web portal, select a medical specialty, choose a doctor, determine an available time for a video consultation, book the service, complete the payment process, fill out the medical history form, and upload all relevant documents for the doctor." 
    }
  ],
  Doctor: [
    { 
      question: "How do I register as a doctor on the Second Opinion platform?", 
      answer: "Simply go to our web portal, click on registration, and select 'Doctor'. Enter your information and confirm the registration email. Please upload your doctor's ID to activate your account. Activation will be completed after Second Opinion verifies your ID as soon as possible." 
    },
    { 
      question: "As a doctor offering my services via the Second Opinion platform, do I have to pay any fees?", 
      answer: "Please read our terms of use. The service fees you need to pay as a doctor are outlined there." 
    },
    { 
      question: "What requirements do I need as a doctor to be able to offer a service via the Second Opinion platform?", 
      answer: "You must upload a valid and recognized medical ID card to the portal, which we verify before granting permission to use the Second Opinion platform. For further conditions and legal requirements, please refer to the terms of use." 
    },
    { 
      question: "From a doctor's perspective, why should I join the Second Opinion platform?", 
      answer: "Second Opinion provides a convenient way to connect with patients seeking expert cancer care. It offers a streamlined process, with the potential for repeated consultations and a global reach to patients in need." 
    },
    { 
      question: "What are the benefits of using this platform compared to traditional consultations?", 
      answer: "The platform offers a direct and efficient way to provide your expertise to a wider range of patients. It removes geographical barriers and provides a streamlined process for both doctors and patients, improving access to cancer care." 
    }
  ],
  "Cancer Type": [
    {
      type: "Ear, Nose, and Throat Cancer",
      questions: [
        {
          question: "I have been experiencing persistent ear pain and was informed it could be related to ear, nose, and throat cancer. What are the typical treatment options for this type of cancer, and are there any specific recommendations for my condition?",
          answer: "Treatment for ear, nose, and throat cancer can vary depending on the exact location and stage of the cancer. Standard treatment options include surgery to remove the tumor, radiation therapy to shrink the tumor, or a combination of both. Chemotherapy may also be recommended in some cases. It is always advisable to seek a second opinion to ensure you explore all possible treatment avenues. VISION MED's Second Opinion service provides access to German medical experts who can offer specialized advice for your condition, ensuring you feel confident and informed [European Journal of Cancer, Vol. 165, April 2022]."
        }
      ]
    },
    {
      type: "Thoracic Oncology (Lung Cancer)",
      questions: [
        {
          question: "I was recently diagnosed with lung cancer, and it has left me feeling scared and uncertain. What are the standard treatment options, and is getting a second opinion necessary?",
          answer: "Lung cancer is a serious concern, and it's understandable to feel anxious. Treatment options depend on the type and stage of your lung cancer. These typically include surgical resection to remove the cancerous tissue, chemotherapy to shrink or kill the cancer cells, and radiation therapy. Getting a second opinion from a specialized provider like VISION MED, which connects you with German medical experts, can offer valuable insight and peace of mind. They will review your case and provide guidance, ensuring you feel empowered to make informed decisions [European Journal of Cancer, Vol. 165, April 2022]."
        }
      ]
    },
    {
      type: "Hematology (Blood Cancers)",
      questions: [
        {
          question: "My recent blood work showed some abnormal results, and my doctor suspects leukemia. What should be my next steps, and is a second opinion really necessary for blood cancers?",
          answer: "Abnormal blood work can be an early indicator of blood cancer, and it's crucial to take prompt action. Your doctor will likely recommend further tests, including a bone marrow biopsy, to confirm a diagnosis. Seeking a second opinion from hematology specialists is highly recommended for blood cancers. VISION MED's Second Opinion service offers access to German hematology experts who can provide valuable insight. They will review your case and ensure you are on the right path forward."
        }
      ]
    },
    {
      type: "Breast Cancer",
      questions: [
        {
          question: "I found a lump in my breast, and my doctor suspects it might be cancerous. Are there different types of breast cancer, and what are the typical treatment options? Should I also consider a second opinion?",
          answer: "Finding a lump can be concerning, and it's important to get a definitive diagnosis. Yes, there are different types of breast cancer, and treatment options vary depending on the specific type and stage. Standard treatments include surgery (lumpectomy or mastectomy), radiation therapy, chemotherapy, and targeted therapies. Seeking a second opinion is always beneficial, and VISION MED can connect you with German breast cancer specialists. They will review your case and provide valuable guidance [Santucci et al., 2022]."
        }
      ]
    },
    {
      type: "Gastrointestinal Oncology",
      questions: [
        {
          question: "I have been experiencing persistent digestive issues, and my doctor mentioned the possibility of gastrointestinal cancer. What are the treatment options for this type of cancer, and is getting a second opinion common in these cases?",
          answer: "Gastrointestinal cancer encompasses cancers of the digestive system, including the esophagus, stomach, intestines, and related organs. Treatment options vary depending on the specific type and location of the cancer but often include surgery, chemotherapy, and radiation therapy. It is quite common and highly recommended to seek a second opinion for gastrointestinal cancers. VISION MED's German medical experts can offer specialized knowledge and ensure you are exploring all appropriate treatment options."
        }
      ]
    },
    {
      type: "Gynecological Cancers",
      questions: [
        {
          question: "During my recent pelvic exam, my OB-GYN found some abnormal tissue, and ovarian cancer is suspected. What are the typical treatments for this, and should I seek a second opinion?",
          answer: "Ovarian cancer is a serious concern, and further testing is necessary for a definitive diagnosis. Treatment options may include surgery to remove the ovaries and affected tissues, chemotherapy, and in some cases, radiation therapy. Seeking a second opinion is common and crucial for gynecological cancers. VISION MED's Second Opinion service provides access to German gynecological oncology specialists who will review your case and offer valuable guidance."
        }
      ]
    },
  
    {
      type: "Urological Oncology (Prostate Cancer)",
      questions: [
        {
          question: "My prostate-specific antigen (PSA) levels are elevated, and my doctor mentioned prostate cancer as a possibility. What does this mean, and are there treatment options available? Should I also get a second opinion?",
          answer: "Elevated PSA levels can be an early indicator of prostate cancer, but further tests are needed for a definitive diagnosis. Treatment options depend on the stage and aggressiveness of the cancer and may include active surveillance, surgery, radiation therapy, or chemotherapy. Seeking a second opinion is always beneficial, and VISION MED can connect you with German urological oncology specialists. They will review your case and help you navigate the most appropriate treatment path."
        }
      ]
    }
  ],

 
};

const page = {
  title: "Frequently Asked Questions",
  disclaimer:"Please note that these are generic responses, and specific treatment recommendations may vary based on individual patient cases."
};

export default { faqs, page };
