import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, createSubscribeNewsletterRequestOptions } from '../config/config';
import Loader from './Loader';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import footerLogo from "../assets/images/footerlogo.png";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const formattedEmail = email.toLowerCase();
    if (!validateEmail(formattedEmail)) {
      setError('Please enter a valid email address.');
      return;
    }

    const requestBody = {
      email: formattedEmail,
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${BASE_URL}/send-newsletter-email-to-admin`,
        createSubscribeNewsletterRequestOptions(requestBody)
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.status === 'Success') {
        setLoading(false);
        toast.success('Email submitted successfully!');
        setEmail(''); // Clear the input field on success
      } else {
        toast.error('Submission failed, please try again.');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      toast.error('There was a problem with the submission.');
    } finally {
      setLoading(false);
    }
  };

  const handleNavigation = (path, sectionId) => {
    // Check if the user is already on the target page
    if (location.pathname === path) {
      if (!sectionId) {
        // Scroll to the top of the page if no sectionId is provided
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        // Scroll to the specific section if sectionId is provided
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        } else {
          // If the section isn't found, scroll to the top
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    } else {
      // Navigate to the new page
      navigate(path);
  
      // Wait for a short time to ensure navigation occurs, then scroll
      setTimeout(() => {
        if (sectionId) {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, 500); // Adjust delay if needed based on how long the new page takes to load
    }
  };
  
  

  return (
    <footer className="bg-[#2A60D6] text-white py-3 md:py-20">
    <div className="mx-auto px-4 sm:px-6 lg:pl-[5%] lg:pr-[3%] max-w-screen-2xl">
      <div className="flex flex-wrap md:flex-nowrap justify-between items-start space-y-6 md:space-y-0 md:gap-x-[9rem]">
        {/* Logo Section */}
        <div className="order-4 md:order-1 flex-grow md:flex-grow-0 w-full md:w-1/5 text-center md:text-left py-12 md:py-0">
            <Link to="/" className="flex flex-col items-center md:items-center">
              <img src={footerLogo} alt="VISION MED Logo" className="w-[18rem] md:w-48 mb-2" />
            </Link>
            <p className="md:text-sm text-[1rem] mt-4 text-center font-medium ">Digital platform connecting oncology doctors and patients</p>
          </div>

        {/* Learn, Visit, Connect in one div with 25% width and 75% width distribution */}
        <div className="order-1 md:order-2 flex-grow md:flex-grow-0 w-full md:w-3/4 text-left py-2 md:py-0">
          <div className="grid grid-cols-1 md:grid-cols-[25%_75%] gap-8">
            {/* Section Titles and Links */}
            <div>
              <h3 className="text-3xl md:text-2xl font-bold mb-4">Learn</h3>
              <ul className="space-y-1">
                <li>
                  <span onClick={() => handleNavigation('/', 'how-it-works')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block cursor-pointer">
                    How the Platform Works
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigation('/', 'why-choose-us')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block cursor-pointer">
                    Why Choose Us
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigation('/', 'our-doctors')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block cursor-pointer">
                    Our Doctors
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigation('/', 'our-partners')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block cursor-pointer">
                    Our Partners
                  </span>
                </li>
              </ul>
            </div>
  
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Visit Section */}
              <div>
                <h3 className="text-3xl md:text-2xl font-bold mb-4">Visit</h3>
                <ul className="space-y-1">
                <li>
  <Link to="/blog"  onClick={() => handleNavigation('/blog', 'top')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block">
    Helpful Articles
  </Link>
</li>
                  <li>
                    <Link to="/faq"   onClick={() => handleNavigation('/faq', 'top')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block">
                      Frequent Questions
                    </Link>
                  </li>
                  <li>
  <Link to="/imprint" onClick={() => handleNavigation('/imprint', 'top')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block">
    Imprint
  </Link>
</li>

                  <li>
                    <Link to="/terms-and-conditions"   onClick={() => handleNavigation('/terms-and-conditions', 'top')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block">
                      Terms Of Use
                    </Link>
                  </li>
                  <li>
                    <Link to="/data-protection"   onClick={() => handleNavigation('/data-protection', 'top')} className="footer-link md:text-sm text-[1rem] tracking-wide font-medium hover:underline block">
                      Data Protection Policy
                    </Link>
                  </li>
                </ul>
              </div>
  
              {/* Connect Section */}
              <div  className='md:w-80 connect'>
                <h3 className="text-3xl md:text-2xl font-bold mb-4">Connect</h3>
                <p className="mb-2 font-medium md:text-sm text-[1rem] tracking-wide">support@vision-med.eu</p>
                <p className="mb-4 font-medium md:text-sm text-[1rem] tracking-wide">Sign up to become a part of the community</p>
                <form className="flex font-medium justify-center md:justify-start " onSubmit={handleSubmit}>
  <input
    type="email"
    placeholder="jane@janesdomain.net"
    className={`p-2  bg-white tracking-wide md:text-sm text-[1rem] rounded-lg text-black w-full  border ${error ? 'border-red-500' : 'border-[#2A60D6]'} focus:outline-none focus:border-[#2A60D6]`}
    value={email}
    onChange={(e) => setEmail(e.target.value)}
  />
  
  <button
    type="submit"
    className={`bg-[#2A60D6] tracking-wide md:text-sm text-[1rem] p-2 ml-2 text-white border border-white rounded-lg  ${loading ? 'opacity-50 cursor-not-allowed px-6 py-2' : 'px-4 py-3'}`}
    disabled={loading}
  >
    {loading ? <Loader /> : 'Submit'}
  </button>
</form>

{error && <p className="text-red-500 err leading-1">{error}</p>}

<style jsx>{`
  @media (min-width: 768px) and (max-width: 1024px) {
    form {
      flex-direction: column;
      align-items: stretch;
    }

    form input {
      width: 130%;
      margin-bottom: 10px;
    }
      .connect{
      width:190%
      }

    form button {
    margin-top:"2px";
      width: 40%;
    }
    
    .err {
      width: 120%; 
      margin-top: 5px; 
    }
  }
`}</style>

              
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="mt-4 md:mt-10 text-center md:text-sm text-[1rem]">
        <p className="mt-1 font-medium tracking-wide">VISION MED GmbH, Julius-Hatry-Str. 1, 68163 Mannheim, Germany</p>
        <p className="mt-1 font-medium tracking-wide">Company Registration Number: HRB 750018</p>
        <p className="mt-1 mb-6 font-medium tracking-wide">© All rights reserved</p>
      </div>
    </div>
    <ToastContainer />
  </footer>
  
  
  
  );
};

export default Footer;
