import React from 'react';

const CustomMap = () => {
  return (
    <div className="relative w-full max-w-full mx-auto">
      <div className="relative w-full h-[15rem] md:h-[20rem] rounded-lg overflow-hidden">
        <iframe
          width="100%"
          height="100%"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=auto&height=auto&hl=en&q=Julius-Hatry-Straße%201,%2068163%20Mannheim,%20Germany&ie=UTF8&t=&z=16&iwloc=B&output=embed"
          title="MAFINEX Technologiezentrum"
          className="iframe-container"
          style={{ pointerEvents: 'auto' }} 
          tabIndex="-1" 
        ></iframe>
      </div>
      <style jsx>{`
        /* Remove focus outline for iframes */
        iframe {
          border: none; /* Ensure no border is present */
        }

        iframe:focus,
        iframe:focus-visible {
          outline: none;
          border: none;
        }

        /* Global focus outline reset */
        *:focus {
          outline: none;
        }

        /* Override Google Maps dynamic overlay styles */
        div[style*='z-index: 1000002'] {
          border: none !important;
        }

   div[style*="pointer-events: none; width: 100%; height: 100%; box-sizing: border-box; position: absolute; z-index: 1000002; opacity: 0; border: 2px solid rgb(26, 115, 232);"] {
  box-shadow: none !important;
  border:none !important;
}


      `}</style>
    </div>
  );
};

export default CustomMap;
