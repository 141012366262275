// config.js
    const BASE_URL = 'https://vision-med.eu:8001/api/v1';

const createRequestOptions = (method, body) => {
  
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
};

  const createContactFormRequestOptions = (values) => {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
  };


  const createSubscribeNewsletterRequestOptions = (values) => {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
  };





export { BASE_URL, createRequestOptions,createContactFormRequestOptions,createSubscribeNewsletterRequestOptions};


