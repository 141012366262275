import React from 'react';

function NotFound() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-[#0A1E54">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <h2 className="text-2xl font-semibold mb-2">Page Not Found</h2>
      <p className="text-lg mb-4">Sorry, the page you are looking for does not exist.</p>
      <a href="/" className="text-[#2757C0] hover:text-[#2757C0] text-lg">
        Go back to Home
      </a>
    </div>
  );
}

export default NotFound;
