import React, { useState, useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import faqData from "./FaqData";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";

const { faqs, page } = faqData;

const FAQ = () => {
  useScrollToTop();
  const [activeSection, setActiveSection] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    [Object.keys(faqs)[0]]: true,
  });
  const [isMobile, setIsMobile] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const scrollToElement = (id) => {
    scroller.scrollTo(id, {
      smooth: true,
      duration: 300,
      offset: -70,
    });
  };

  useEffect(() => {
    if(!isMobile){
    const handleScroll = () => {
      const sections = document.querySelectorAll(".faq-section, #disclaimer");
      let newActiveSection = null;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          newActiveSection = section.id;
        }
      });

      if (newActiveSection !== activeSection) {
        setActiveSection(newActiveSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }
  }, [isMobile,activeSection]);

  const handleNavClick = (sectionId) => {
    setActiveSection(sectionId);
    scrollToElement(sectionId);
  };

  const handleMobileNavToggle = () => {
    setMobileNavOpen((prevState) => !prevState);
  };

  console.log("activeSection",activeSection);
  

  return (
    <>
      <Navbar />
      <div className={`min-h-screen bg-[#F7F9FF] ${isMobile ? "pt-0" : "pt-20"}`}>
        <div className="mx-auto md:pl-[5.860%] md:pr-[5%]">
          {/* Move contents to be directly under the navbar in mobile */}
          {isMobile && (
            <div className="md:hidden w-full mb-6">
              <div className="bg-white p-2  px-5">
                <button
                  className="flex justify-between items-center w-full text-lg font-semibold text-[#0A1E54]"
                  onClick={handleMobileNavToggle}
                >
                  <span>Contents</span>
                  {mobileNavOpen ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {mobileNavOpen && (
                  <ul className="mt-4 space-y-2">
                    {Object.keys(faqs).map((section, idx) => (
                      <div key={idx}>
                        <h2
                          className={`flex justify-between items-center text-xl font-semibold cursor-pointer ${
                            expandedSections[section]
                              ? "text-[#0A1E54]"
                              : "text-[#0A1E54]"
                          }`}
                          onClick={() => toggleSection(section)}
                        >
                          <span>
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                          </span>
                          {expandedSections[section] ? (
                            <FaChevronUp className="ml-2" />
                          ) : (
                            <FaChevronDown className="ml-2" />
                          )}
                        </h2>
                        {expandedSections[section] && (
                          <ul className="ml-4 mb-2 space-y-2 mt-4">
                            {section === "Cancer Type"
                              ? faqs[section].map((category, index) => (
                                  <div key={index}>
                                    <h3
                                      className={`text-lg font-semibold mt-4 mb-2 cursor-pointer ${
                                        activeSection ===
                                        `category-${section}-${index}`
                                          ? "text-[#2A60D6]"
                                          : "text-[#0A1E54]"
                                      }`}
                                      onClick={() =>
                                        handleNavClick(
                                          `category-${section}-${index}`
                                        )
                                      }
                                    >
                                      {category.type}
                                    </h3>
                                    {category.questions.map((faq, qIndex) => (
                                      <li key={qIndex} className="mb-3">
                                        <a
                                          href={`#faq-${section}-${index}-${qIndex}`}
                                          className={`block font-inter font-medium ${
                                            activeSection ===
                                            `category-${section}-${index}`
                                            ? "text-[#2A60D6]"
                                            : "text-[#0A1E54]"
                                        }`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleNavClick(
                                              `category-${section}-${index}`
                                            );
                                          }}
                                        >
                                          {faq.question}
                                        </a>
                                      </li>
                                    ))}
                                  </div>
                                ))
                              : Array.isArray(faqs[section]) &&
                                faqs[section].map((faq, index) => (
                                  <li key={index} className="mb-3">
                                    <a
                                      href={`#faq-${section}-${index}`}
                                      className={`block font-inter font-medium ${
                                        activeSection ===
                                        `faq-${section}-${index}`
                                          ? "text-[#2A60D6]"
                                          : "text-[#0A1E54]"
                                      }`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleNavClick(
                                          `faq-${section}-${index}`
                                        );
                                      }}
                                    >
                                      {faq.question}
                                    </a>
                                  </li>
                                ))}
                          </ul>
                        )}
                      </div>
                    ))}
                    <li>
                      <button
                        className={`text-xl font-semibold flex items-center cursor-pointer space-x-2 ${
                          activeSection === "disclaimer"
                            ? "text-[#2A60D6]"
                            : "text-[#0A1E54]"
                        }`}
                        onClick={() => handleNavClick("disclaimer")}
                      >
                        Disclaimer
                      </button>
                    </li>
                  </ul>
                )}
              </div>
              <hr
          className='md:hidden'
          style={{
            borderTop: "1px solid rgba(42, 96, 214, 1)",
            width: "100%",
            margin: "auto",
          }}
        />
            </div>
          )}
        

          {/* Title */}
          <h1
            className={`text-[#2757C0] md:px-0 px-5  text-4xl md:text-[3.2rem] font-semibold mb-6 font-helvetica md:text-justify md:bg-transparent bg-[#F7F9FF] md:py-2 py-4 rounded-lg`}
          >
            {page.title}
          </h1>

          <div
            className={`flex flex-col md:flex-row gap-8   py-4 md:mt-[5rem] ${
              isMobile ? "bg-white " : ""
            }`}
          >
            {/* Sidebar for Desktop */}
            <div className="w-full md:w-[45%] py-10 hidden md:block">
              <nav className="sticky top-20 max-h-[50vh] overflow-y-auto pr-2 custom-scrollbar">
                <ul className="text-[#0A1E54] ">
                  {Object.keys(faqs).map((section, idx) => (
                    <div key={idx}>
                      <h2
                      
                        className={`flex items-center md:text-justify text-[26px] font-semibold mb-4 mt-[25px] cursor-pointer font-helvetica ${
                          expandedSections[section]
                            ? "text-[#0A1E54]"
                            : "text-[#0A1E54]"
                        }`}
                        onClick={() => toggleSection(section)}
                      >
                        <span>
                          {section.charAt(0).toUpperCase() + section.slice(1)}
                        </span>
                        {expandedSections[section] ? (
                          <FaChevronUp className="ml-1" /> // Reduced margin
                        ) : (
                          <FaChevronDown className="ml-1" /> // Reduced margin
                        )}
                      </h2>
                      {expandedSections[section] && (
                        <ul className="mb-2 ">
                          {section === "Cancer Type"
                            ? faqs[section].map((category, index) => (
                                <div key={index}>
                                  <h3
                                    className={`md:text-justify  font-semibold  font-helvetica mt-4 mb-2 cursor-pointer  text-[1.20rem]  ${
                                      activeSection ===
                                      `category-${section}-${index}`
                                        ? "text-[#2A60D6]"
                                        : "text-[#0A1E54]"
                                    }`}
                                    onClick={() =>
                                      handleNavClick(
                                        `category-${section}-${index}`
                                      )
                                    }
                                  >
                                    {category.type}
                                  </h3>
                                  {category.questions.map((faq, qIndex) => (
                                    <li key={qIndex} className="mb-3">
                                      <a
                                        href={`#faq-${section}-${index}-${qIndex}`}
                                        className={`md:text-justify block font-helvetica   text-[1.10rem] font-semibold ${
                                          activeSection ===
                                          `category-${section}-${index}`
                                            ? "text-[#2A60D6]"
                                            : "text-[#0A1E54]"
                                        }`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleNavClick(
                                            `faq-${section}-${index}-${qIndex}`
                                          );
                                        }}
                                      >
                                        {faq.question}
                                      </a>
                                    </li>
                                  ))}
                                </div>
                              ))
                            : Array.isArray(faqs[section]) &&
                              faqs[section].map((faq, index) => (
                                <li key={index} className="mb-3">
                                  <a
                                    href={`#faq-${section}-${index}`} 
                                    className={`md:text-justify block font-helvetica   text-[1.10rem] font-semibold ${
                                      activeSection ===
                                      `faq-${section}-${index}`
                                        ? "text-[#2A60D6]"
                                        : "text-[#0A1E54]"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleNavClick(`faq-${section}-${index}`);
                                    }}
                                  >
                                    {faq.question}
                                  </a>
                                </li>
                              ))}
                        </ul>
                      )}
                    </div>
                  ))}
                  <li className="mb-3">
                    <button
                      className={`text-[26px] md:text-justify font-semibold mb-4 mt-[25px] flex items-center cursor-pointer space-x-2 font-helvetica ${
                        activeSection === "disclaimer"
                          ? "text-[#2A60D6]"
                          : "text-[#0A1E54]"
                      }`}
                      onClick={() => handleNavClick("disclaimer")}
                    >
                      Disclaimer
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            {/* Main Content */}
            <div className="w-full md:w-[89%] md:ml-[10%]">
              {Object.keys(faqs).map((section, idx) => (
                <div key={idx} className="mb-16 px-5">
                  <h2 className="md:text-justify text-[2rem] font-helvetica font-semibold text-[#2757C0] mb-6 md:mb-10">
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </h2>
                  {section === "Cancer Type"
                    ? faqs[section].map((category, index) => (
                        <div
                          key={index}
                          className="mb-12 faq-section category-section"
                          id={`category-${section}-${index}`}
                        >
                          <h3 className="md:text-justify text-2xl font-inter font-bold text-[#2757C0] mb-8">
                            {category.type}
                          </h3>
                          {category.questions.map((faq, qIndex) => (
                            <Element
                              name={`faq-${section}-${index}-${qIndex}`}
                              id={`faq-${section}-${index}-${qIndex}`}
                              key={qIndex}
                              className="mb-12"
                            >
                              <h3
                                id={`question-${section}-${index}-${qIndex}`}
                                className={`md:text-justify text-2xl font-helvatica font-semibold text-[#2757C0] mb-4 ${
                                  activeSection ===
                                  `category-${section}-${index}`
                                    ? "text-[#2A60D6]"
                                    : "text-[#0A1E54]"
                                }`}
                              >
                                {faq.question}
                              </h3>
                              <p
                                className="md:text-justify text-lg text-[#00103C] font-inter "
                                dangerouslySetInnerHTML={{ __html: faq.answer }}
                              ></p>
                            </Element>
                          ))}
                        </div>
                      ))
                    : Array.isArray(faqs[section]) &&
                      faqs[section].map((faq, index) => (
                        <Element
                          name={`faq-${section}-${index}`}
                          id={`faq-${section}-${index}`}
                          key={index}
                          className="mb-12 faq-section"
                        >
                          <h2
                            className={`md:text-justify text-2xl font-helvatica font-semibold text-[#2757C0] mb-4 ${
                              activeSection === `faq-${section}-${index}`
                                ? "text-[#2A60D6]"
                                : "text-[#2757C0]"
                            }`}
                          >
                            {faq.question}
                          </h2>
                          <p
                            className="md:text-justify text-lg text-[#00103C] font-inter "
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          ></p>
                        </Element>
                      ))}
                </div>
              ))}
              <div className="mt-12 px-5">
                <Element name="disclaimer" id="disclaimer" className="mb-12">
                  <h2
                    className={`md:text-justify text-[2rem] font-inter text-[#2757C0] mb-4 font-semibold rounded-lg md:bg-transparent md:p-0 ${
                      isMobile ? "bg-white" : "bg-[#F7F9FF]"
                    }`}
                  >
                    Disclaimer
                  </h2>
                  <p
                    className="md:text-justify text-lg text-[#00103C] font-inter"
                    dangerouslySetInnerHTML={{ __html: page.disclaimer }}
                  ></p>
                </Element>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #00103c #f7f9ff;
          }
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(0, 16, 60, 0.7); /* Dark blue with slight transparency */
            border-radius: 60px !important;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f7f9ff; /* Light background color */
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
