// src/AboutUsData.js

import DoctorImg from "../../assets/images/doctorimage.png";
import UniversitaIcon from "../../assets/images/Universita.png";
import OnkologieBethanien from "../../assets/images/OnkologieBethanien.png";
import Iuvando from "../../assets/images/Iuvando.png";
import SKIY31 from "../../assets/images/SKIY31.png";
import Bundesministerium from "../../assets/images/Bundesministerium.png";
import BadenWurttemberg from "../../assets/images/BadenWurttemberg.png";
import LifeScienceAccelerator from "../../assets/images/LifeScienceAccelerator.png";
import EITHealth from "../../assets/images/EITHealth.png";
import MannheimBusinessSchool from "../../assets/images/MannheimBusinessSchool.png";
import NextMannheim from "../../assets/images/NextMannheim.png";
import Technologiezentrum from "../../assets/images/MAFINEXTechnologiezentrum.png";
import Uwe from "../../assets/images/Uwe2.png";
import Konstantin from "../../assets/images/Konstantin3.png";
import Christian from "../../assets/images/Christian1.png";
import derbruderhof from "../../assets/images/derbruderhof.png";
import { FlagIcon } from 'react-flagkit';
import docthird from "../../assets/images/docthird.png";
const aboutUsContent = {
  logo:{
    heading:"We also got support and promotion from:"
  },
  intro: {
    title: "We believe every person deserves access to world-class healthcare",
    description:
      "At Second Opinion by VISION MED, our vision is to prevent avoidable cancer deaths by leveraging technology to connect patients directly with top specialists. Our mission is deeply rooted in patient centricity—we strive to simplify this interaction, providing rapid and expert second opinions to those who need it most. We understand the emotional journey cancer patients and their families face, and we are committed to improving health outcomes for those who are underserved. With swift medical feedback and affordable services, we aim to remove obstacles and make quality healthcare accessible to all. Together, let's ensure that no one has to endure unnecessary suffering or uncertainty. ",
  },
  backedBy: {
    title:
      "Backed by German government and trustworthy organizations, we’re bringing our vision to life.",
  },
  subItems:{
    
    title:"Vision Med is a team of experienced professionals",
    title2:`We’re located in MAFINEX Technologiezentrum in Mannheim, Germany`,
    address:"MAFINEX Technologiezentrum",
       address1:"Julius-Hatry-Str. 1, 68163 Mannheim, Germany"  
  },
   teamMembers : [
    {
      name: "Dr. Christian Schmitt",
      title: "Chief Medical Officer",
      specialty: "Oncologist",
      experience: ">10 years medical experience",
      imgSrc: Christian,
      logos: [UniversitaIcon, OnkologieBethanien],
    },
 


    {
      name: "Uwe Zell",
      title: `Chief Executive Officer<br/>Business Development`,
      specialty: "MBA",
      experience: `Serial entrepreneur<br/> >10 years experience`,
      imgSrc: Uwe,
      logos: [MannheimBusinessSchool,derbruderhof],
    },
    // {
    //   name: "Wilfride Petnga",
    //   title: "Chief Operating Officer",
    //   specialty: "PhD, MBA",
    //   experience:
    //     "EIT Health Female Founders alumna,<br/> 3+ years of start-up experience",
    //   imgSrc: DoctorImg,
    //   logos: [UniversitaIcon, OnkologieBethanien],
    // },
    {
      name: "Konstantin Shcherbina",
      title: `Chief Technical Officer<br/>Chief Marketing Officer`,
      specialty: "MBA",
      experience: `Serial entrepreneur<br/> >10 years experience`,
      imgSrc: docthird,
      logos: [MannheimBusinessSchool,SKIY31],
    },
  ],
  organizations: [
    { name: "Bundesministerium", imgSrc: Bundesministerium },
    { name: "Baden Wurttemberg", imgSrc: BadenWurttemberg },
    { name: "Life Science Accelerator", imgSrc: LifeScienceAccelerator },
    { name: "EIT Health", imgSrc: EITHealth },
    { name: "Mannheim Business School", imgSrc: MannheimBusinessSchool },
    { name: "Next Mannheim", imgSrc: NextMannheim },
  ],
  cooperationItems: [
    {
      name: "Onkologie Bethanien Clinic",
      imgSrc: OnkologieBethanien,
      description:
        "Our first success story is a cooperation with the clinic in Frankfurt, which was instrumental in the pilot phase.",
    },
    {
      name: "iuvando",
      imgSrc: Iuvando,
      description:
        "Assigned selected patients for clinical trials of novel treatments for pharma companies. Attractive for patients and cooperating doctors and clinics.",
    },
    {
      name: "SKIY31",
      imgSrc: SKIY31,
      description:
        "Inhouse low-cost team for development and digital marketing in India, Ukraine, Romania, Poland. Has local presence in countries.",
    },
  ],
  locationData: {
    imgSrc: Technologiezentrum,
    name: "MAFINEX Technologiezentrum",
    address: "Julius-Hatry-Str. 1, 68163 Mannheim, Germany",
  },
  
};

export default aboutUsContent;
