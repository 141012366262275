import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BASE_URL,
  createRequestOptions
} from "../../config/config";
import { useParams } from "react-router";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";
import Loader from "../../Pages/Loader";
import noImg from "../../assets/images/no-img.jpg";
const BlogArticlePage = () => {
  useScrollToTop();
  const location = useLocation();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const { allArticles } = location.state || {}; // Check if `id` is present in the state
  const [allArticle, setAllArticle] = useState(allArticles);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const params = useParams();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const requestOptions = createRequestOptions("POST");
        const url = `${BASE_URL}/blog/list`;

        const response = await fetch(url, requestOptions);
        const result = await response.json();
        if (result.statusCode === 200) {
          const articlesData = result.data;
          setAllArticle(articlesData);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [!allArticles]);
  const rawHTML = article?.description?.en || "";

  const transformHTML = (htmlString) => {
    return htmlString
      .replace(
        /<h2>/g,
        '<h2 class="text-[#2757C0] text-[2rem] font-semibold mb-6 font-helvetica md:mt-16 mt-3">'
      )
      .replace(
        /<h3>/g,
        '<h3 class="text-2xl mt-8 font-helvetica font-semibold px-0 text-[#2757C0] mb-2">'
      )
      .replace(
        /<h4>/g,
        '<h4 class="text-xl font-semibold text-[#011C65] font-helvetica">'
      ).replace(
        /<ul>/g,
        '<ul class="list-disc list-inside leading-loose mb-8">'
      )
      .replace(
        /<a/g,
        '<a target="_blank"')
      .replace(
        /<p>/g,
        '<p class="text-[#000000] text-[20px] font-helvetica leading-relaxed mb-8 break-words md:break-normal">'
      )
      .replace(/<\/p>/g, "</p>");
  };
  const transformedHTML = transformHTML(rawHTML);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!params.id) {
        setLoading(false);
        return;
      }

      try {
        const requestBody = JSON.stringify({ id: params.id });
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        };

        const response = await fetch(
          `${BASE_URL}/blog/get-by-id`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.statusCode === 200) {
          setArticle(result.data);
          window.scrollTo({ top: 0, behavior: 'auto' });
        } else {
          console.error("Failed to fetch article:", result.message);
        }
      } catch (error) {
        console.error("Error fetching the article:", error);
      } finally {
        setLoading(false);
      }
    };
    console.log(params.id)
    fetchArticle();
  }, [params.id]);

  return (
    <>
      <style>
        {`
    /* Default desktop view (up to 1400px) */
    .featured-article {
      display: grid;
      grid-template-columns: 31% 65%; /* Default layout */
      gap: 3rem; /* Adjust default gap */
    }

    /* For screens larger than 1400px */
   @media (min-width: 1640px) {
  .featured-article {
    grid-template-columns: 28% 58%; /* Adjusted layout for larger screens */
    gap: 2rem; /* Adjust the gap for larger screens */
  }
}

    /* Adjust for iPad and smaller screens */
    @media (max-width: 1024px) {
      .featured-article {
      grid-template-columns: 31% 59%;
        grid-template-columns: 1fr; /* Single column layout for iPad and mobile */
        gap: 1rem;
      }

      .mobile-img {
        width: 100%;
        height: auto;
      }
    }

    /* Mobile-specific styles */
    @media (max-width: 767px) {
    
        .featured-article {
    
      gap: 1rem; /* Adjust default gap */
    }
    }
  `}
      </style>

      <Navbar />
      {loading ? (
        <div className="w-full h-[600px] flex items-center justify-center">
          <Loader />
        </div>
      ) : article ? (
        <div className="bg-[#F7F9FF] font-helvetica">
          <div className="md:px-[6%] px-4 md:pt-[5%] md:pb-10 py-10">
            <div className="featured-article items-center">
              <div className="flex justify-center md:justify-start md:h-[370px] md:w-[370px] md:max-h-[370px]  w-full h-auto max-h-[240px]  ">

                <img
                  src={article?.imgUrl || noImg}
                  alt="Blog"
                  className="
                  rounded-lg 
                  object-cover shadow-lg "
                />
              </div>
              <div className="flex flex-col justify-left  mt-2">
                <h3 className="text-[#2757C0]  md:text-[2.65rem] text-4xl  font-semibold tracking-normal  font-helvetica leading-tight">
                  {article?.title?.en}
                </h3>
                <p className="text-[#192C5F] mt-2 md:text-justify text-[17.30px] font-medium font-helvetica w-auto md:max-w-[75%] leading-normal">
                  {article?.overview?.en}
                </p>
              </div>
            </div>

            <div className="md:mt-10 mt-10 md:pr-[27%] mx-auto">
              <p
                className="text-[#000000] text-[20px] leading-tight md:text-justify font-helvetica mb-6"
                dangerouslySetInnerHTML={{ __html: transformedHTML }}
              />
            </div>
          </div>

          {allArticle?.length > 1 ? (
            <div className="bg-[#EBF0FF] md:px-[6%] md:py-10 py-3  px-4 pb-[3rem]">
              <h2 className="text-[#2757C0] text-[2.320rem] font-semibold font-helvetica mb-6">
                Learn More
              </h2>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-[6rem] gap-8">
                {allArticle
                  ?.filter((articleItem) => articleItem._id !== article?._id) // Filter out the current article
                  .map((article) => (
                    <div
                      key={article._id}
                      className="flex flex-col md:items-start ">
                      <div className="flex justify-center md:justify-start h-auto ">
                        <img
                          onClick={() => {
                            navigate(`/blogarticle/${article._id}`, {
                              state: {
                                id: article._id,
                                allArticle: allArticle,
                              },
                            });
                          }}
                          src={article.imgUrl || noImg}
                          alt={article.title.en}
                          className="cursor-pointer rounded-lg w-full h-auto max-h-[240px] md:w-[180px] md:h-[180px] object-cover shadow-lg "


                        />
                      </div>
                      <h3 onClick={() => {
                        navigate(`/blogarticle/${article._id}`, {
                          state: {
                            id: article._id,
                            allArticle: allArticle,
                          },
                        });
                      }}
                        className="text-[#2757C0] cursor-pointer mt-3 mb-5  md:text-[1.600rem] text-4xl font-semibold  tracking-normal font-helvetica leading-tight">
                        {article.title.en}
                      </h3>

                      <p className="text-[#192C5F] md:text-[17.30px]  text-[18px]   font-helvetica font-medium md:text-justify leading-normal">
                        {article.overview.en}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen text-center">
          
        </div>
      )}
      <Footer />
    </>
  );
};

export default BlogArticlePage;

