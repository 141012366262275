import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useJsApiLoader } from "@react-google-maps/api";
import { BASE_URL, createContactFormRequestOptions } from "../../config/config";
import contactContent from "./contactContent";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Pages/Loader";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import CustomMap from "../../Pages/Map";
import Technologiezentrum from "../../assets/images/MAFINEXTechnologiezentrum.png";

const ContactUs = () => {
  const latitude = 49.473221343269444;
  const longitude = 8.474499586507914;
  const {
    title,
    address,
    subAddress,
    instructions1,
    instructions2,
    placeholders,
    errorMessages,
    buttonText,
  } = contactContent;

  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const mapRef = useRef(null);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email.toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!email) {
      formErrors.email = errorMessages.emailRequired;
    } else if (!validateEmail(email)) {
      formErrors.email = errorMessages.invalidEmail;
    }

    if (!question) {
      formErrors.question = errorMessages.questionRequired;
    } else if (question.length < 10 || question.length > 500) {
      formErrors.question = errorMessages.questionLength;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      const requestBody = {
        email: email.toLowerCase(),
        message: question,
      };

      try {
        setLoading(true);
        const response = await fetch(
          `${BASE_URL}/save-contact-form`,
          createContactFormRequestOptions(requestBody)
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.status === "Success") {
          toast.success("Contact Form submitted successfully!");
          setEmail("");
          setQuestion("");
        } else {
          toast.error("Submission failed, please try again.");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        toast.error("There was a problem with the submission.");
      } finally {
        setLoading(false);
      }
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBadjpsgPXDdz86pSnD7TPe6rBTiXvDJ_0",
  });

  useEffect(() => {
    if (mapRef.current && isLoaded) {
      const map = mapRef.current;
      const overlay = new window.google.maps.OverlayView();

      overlay.onAdd = function () {
        const layer = document.createElement("div");
        layer.className = "custom-overlay";
        layer.innerHTML = `
          <div class="custom-marker">
            <img src=${Technologiezentrum} alt="Technologiezentrum" class="custom-marker-img" />
          </div>
        `;
        this.getPanes().overlayImage.appendChild(layer);
        overlay.layer = layer;
      };

      overlay.draw = function () {
        const projection = this.getProjection();
        const position = new window.google.maps.LatLng(latitude, longitude);
        const point = projection.fromLatLngToDivPixel(position);
        if (point) {
          overlay.layer.style.left = `${point.x}px`;
          overlay.layer.style.top = `${point.y - 50}px`;
        }
      };

      overlay.onRemove = function () {
        if (overlay.layer) {
          overlay.layer.parentNode.removeChild(overlay.layer);
          overlay.layer = null;
        }
      };

      overlay.setMap(map);
    }
  }, [isLoaded]);

  return (
    <>
      <Navbar />
      <div className="bg-[#F7F9FF] flex flex-col min-h-screen">
        <div className="flex-grow md:py-16 py-10 px-[6%]">
          <div className="max-w-8xl md:pr-[5%] xl:max-w-screen-xl">
            <h1 className="text-[#2757C0] text-4xl md:text-[3.2rem] font-semibold mb-9 text-left font-helvetica ">
              {title}
            </h1>
            <div className="grid md:grid-cols-2 gap-8 md:gap-[5rem] items-start">
              <div className="order-2 md:order-1 flex flex-col justify-between items-center md:items-start mt-4 md:mt-[2.55rem]">
                {isLoaded ? (
                  <div className="relative w-full max-w-md mx-auto md:mx-0 border border-[#2A60D6] rounded-lg overflow-hidden p-3 bg-[#F0F6FF]">
                    <div style={{ pointerEvents: "none" }}>
                      <CustomMap latitude={latitude} longitude={longitude} />
                    </div>
                    <div className="text-center mt-4">
                      <p className="text-[#00103C] font-medium text-[14.73px] font-inter">
                        {address}
                      </p>
                      <p className="text-[#00103C] font-medium text-[14.73px] font-inter">
                        {subAddress}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="relative w-full max-w-md mx-auto md:mx-0 border border-[#2A60D6] rounded-lg overflow-hidden p-4 flex items-center justify-center h-64">
                    <Loader />
                  </div>
                )}
              </div>

              <div className="order-1 md:order-2 flex flex-col justify-between">
                <div>
                  <p className="text-[#00103C] w-full mb-5 font-normal text-[20px] font-helvetica md:text-justify">
                    {instructions1}
                  </p>
                  <p className="text-[#00103C] w-full mb-5 font-normal text-[20px] font-helvetica md:text-justify">
                    {instructions2}
                  </p>
                </div>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div>
                    <input
                      type="email"
                      placeholder={placeholders.email}
                      className={`w-full p-3 border rounded-lg focus:outline-none bg-[#F0F6FF] font-helvetica ${
                        errors.email ? "border-red-500" : "border-[#81B7FF]"
                      } focus:border-[#2757C0] placeholder-[#636363] font-normal text-xl`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm mt-1 font-helvetica">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div>
                    <textarea
                      placeholder={placeholders.question}
                      className={`w-full p-3 mt-3 md:mt-1 h-40 border rounded-lg focus:outline-none font-helvetica bg-[#F0F6FF] ${
                        errors.question ? "border-red-500" : "border-[#81B7FF]"
                      } focus:border-[#2757C0] placeholder-[#636363] font-normal text-xl`}
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                    {errors.question && (
                      <p className="text-red-500 text-sm mt-1 font-helvetica">
                        {errors.question}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-start mt-auto">
                    <button
                      type="submit"
                      className={`bg-[#05BDFB] font-helvetica text-white rounded-lg hover:bg-[#05BDFB] focus:outline-none font-semibold w-full md:w-auto ${
                        loading
                          ? "opacity-50 cursor-not-allowed px-10 py-3"
                          : "px-7 py-3"
                      }`}
                      disabled={loading}
                    >
                      {loading ? <Loader /> : buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer className="mt-auto" />
      </div>
      <ToastContainer />
    </>
  );
};

export default ContactUs;
