// src/imprintData.js
const imprintData = {
    serviceProvider: {
      title:"Imprint",
    
         serviceprovider: {
          name: "Service Provider/Company",
          subtext: "Service provider of this online service and responsible for the content of this site according to § 7 TMG: VISION Med GmbH, Julius-Hatry-Str. 1, 68163 Mannheim, Germany",
        },


        representative: {
        name: "Representative",
        subtext: "Represented by the managing directors Dr. Christian Schmitt, Uwe Zell, Konstantin Shcherbina, Wilfride Vidale Petnga Kamdoum.",
      },
      email: {
        name: "E-Mail",
        subtext: (
          <a href="mailto:support@vision-med.eu" className=" underline">
            support@vision-med.eu
          </a>
        ),
      },
      web: {
        name: "Web",
        subtext: (
          <a href="https://www.vision-med.eu" target="_blank" rel="noopener noreferrer" className=" underline">
            www.vision-med.eu
          </a>
        ),
      },
      
      companyRegisteredOffice: {
        name: "Company's Registered Office",
        subtext: "Mannheim, Germany",
      },
      localcourt: {
        name: "Local Court",
        subtext: "Mannheim",
      },
      companyRegisterNumber: {
        name: "Company Register Number",
        subtext: "HRB 750018",
      },
      vatnumber: {
        name: "VAT Number",
        subtext: "DE342112924",
      },
      disputeresolution: {
        name: "Dispute Resolution",
        subtexts: `The EU Commission has set up a website for online dispute 
    resolution between companies and consumers, which you 
    can find at <a href="http://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer" class="text-black underline hover:text-black hover:underline">http://ec.europa.eu/consumers/odr</a>.`,
    additionalinfo: `The VISION MED GmbH is neither willing nor obliged to 
        participate in a dispute settlement procedure before a consumer 
        arbitration board. Editorially and responsible for the content 
        according to § 55 ABS. 2 RSTV.`,
        content: `The VISION MED GmbH is responsible for the editorial and content for the service offer and website.`,
        info: `Further information for the use of the Second Opinion Platform 
        you will find at our   <a href="/terms-and-conditions" class="text-blue-600 hover:underline">T&C</a> and the 
        <a href="/data-protection" class="text-blue-600 hover:underline">data protection policy</a>.`,
        infosub: `Our data protection guidelines describe the handling 
        of personal data.`
      },
      
      
        },
  };
  
  export default imprintData;
  

