import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Pages/NavBar';
import Footer from './Pages/Footer';
import Imprint from "./Components/Imprint/imprint";
import ContactUs from './Components/ContactUs/ContactUs';
import DataProtectionPolicy from './Components/DataProtectionPolicy/DataProtectionPolicy';
import Faq from './Components/FAQ/Faq';
import TermsPage from './Components/TermsAndServices/TermsPage';
import AboutUs from './Components/AboutUs/AboutUs';
import Home from './Components/Home/Home';
import NotFound from './Pages/NotFound';
import CookieBanner from './Pages/CookieBanner'; 
import BlogPage from './Components/BlogPage/BlogPage';
import BlogArticlePge from './Components/BlogArticle/BlogArticle';
function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
      
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms-and-conditions" element={<TermsPage />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/data-protection" element={<DataProtectionPolicy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blogarticle/:id" element={<BlogArticlePge />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      
      </div>
      <CookieBanner />
    </Router>
  );
}

export default App;
