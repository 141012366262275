import React from 'react';

const DoctorSection = ({ doctor }) => {
  return (
    <div className="flex flex-col md:flex-row rounded-[1.5rem] mb-8 w-full  mx-auto text-left px-3 md:px-0">
      {/* Image section */}
      <div className="bg-[#F0F6FF] rounded-[1.5rem]  w-[11rem] h-[11rem] flex items-end justify-center" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)' }}>
  <img
    src={doctor.image}
    alt={doctor.name}
    className="rounded-b-[1.5rem] mb-0 w-full h-auto"
  />
</div>


      {/* Content section */}
      <div className="flex md:pl-5 flex-col justify-between h-auto md:h-[12rem] w-full md:w-[65%]">
        <div className="flex flex-col flex-grow">
          <div className="text-2xl md:text-xl text-[#001D6D] font-semibold py-3 md:py-0 font-opensans">
            {doctor.name}
          </div>
          <p
            className="text-2xl md:text-xl text-[#0A1E54] font-normal font-opensans mt-1"
            style={{lineHeight: '1.25rem' }}
          >
            {doctor.position}
          </p>

          {/* Spacer */}
          <div className="flex-grow"></div>
          {doctor.logos?.length > 0 && (
            <div className="flex flex-col justify-end mt-2">
              <p
                className="text-sm text-[#0A1E54] font-medium mb-2 font-opensans"
                dangerouslySetInnerHTML={{ __html: doctor.specialties }}
              ></p>
         <div className="flex flex-row justify-start items-start flex-wrap md:flex-nowrap">
  {doctor.logos.map((logo, logoIndex) => (
    <img
      key={logoIndex}
      src={logo}
      alt={`Logo ${logoIndex + 1}`}
      className="h-10 logo mb-4 md:mb-0  mr-6"
    />
  ))}
</div>

<style jsx>{`
  @media (min-width: 768px) and (max-width: 1024px) {
    
    
    .logo {
      height:1.7rem;
      margin-right:0.7rem
    }
  }
   
  }
`}</style>

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorSection;
