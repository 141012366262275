import React from "react";
import OnkologieBethanien from "../../assets/images/OnkologieBethanien.png";
import Iuvando from "../../assets/images/Iuvando.png";
import SKIY31 from "../../assets/images/SKIY31.png";
import Bundesministerium from "../../assets/images/Bundesministerium.png";
import BadenWurttemberg from "../../assets/images/BadenWurttemberg.png";
import LifeScienceAccelerator from "../../assets/images/LifeScienceAccelerator.png";
import EITHealth from "../../assets/images/EITHealth.png";
import MannheimBusinessSchool from "../../assets/images/MannheimBusinessSchool.png";
import NextMannheim from "../../assets/images/NextMannheim.png";
import DoctorImg from "../../assets/images/doctorimage.png";
import UniversitaIcon from "../../assets/images/Universita.png";
import Technologiezentrum from "../../assets/images/MAFINEXTechnologiezentrum.png";
import aboutUsContent from "./AboutUsData";
import Navbar from "../../Pages/NavBar";
import Footer from "../../Pages/Footer";
import useScrollToTop from "../../config/useScrollToTop";
import AboutImg from "../../assets/images/About.png";
import CustomMap from "../../Pages/Map";
import GermanyFlag from "../../assets/images/flag.png";
const AboutUs = () => {
  useScrollToTop();
  const address = "Julius-Hatry-Straße 1, 68163 Mannheim, Germany";
  const latitude = 49.473221343269444;
  const longitude = 8.474499586507914;
  const encodedAddress = encodeURIComponent(address);
  const API_Key = "AIzaSyBadjpsgPXDdz86pSnD7TPe6rBTiXvDJ_0";
  const {
    logo,
    intro,
    backedBy,
    team,
    organizations,
    cooperationItems,
    locationData,
    teamMembers,
    subItems,
  } = aboutUsContent;
  return (
    <>
      <Navbar />{" "}
      <div className="min-h-screen min-w-screen bg-[#F7F9FF] ">
        <div >
          <div className=" mx-auto mb-12 text-[#2757C0] font-semibold  px-4 md:pl-[6%] md:pr-[5%] ">
            <h1 className="md:leading-[55px] text-4xl  md:text-[3.2rem]  font-helvetica md:w-[85%] md:py-[4rem] py-[3rem]">
              {intro?.title}
            </h1>
            <div className=" flex flex-col md:flex-row justify-between items-center">
              <div className="w-full md:w-[54%] md:mt-[2%] mb-4 md:mb-0">
                <p className="text-[#0A1E54]  text-[1.145rem] leading-loose font-semibold font-helvetica md:text-justify">
                  {intro?.description}
                </p>
              </div>
              <div className="hidden md:block md:w-[40%] md:h-[400px] lg:h-[500px] xl:h-[380px]">
                <img
                  src={AboutImg}
                  alt="About"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#fff] pt-7">
          <div className="text-[#0A1E54] leading-tight  font-semibold  mb-3 md:mt-6 mt-2 h-auto px-4 md:px-[5.8%]">
            <div className="mx-auto md:mt-6 mt-0">
              <h1 className="text-[#0A1E54] backedby md:w-[70%] text-2xl md:text-[2.3rem] md:font-semibold leading-tight mb-6 break-words font-helvetican">
                {backedBy.title}{" "}
              </h1>
            </div>
          </div>
          <div className="mx-auto mb-6 px-7 md:px-[5.9%] md:mt-10 py-6">
            <div className="flex flex-col md:flex-row md:space-x-6">
              <div className="flex flex-col w-full md:w-[40%] space-y-8 mt-3">
                {cooperationItems.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col md:flex-row items-center md:items-start md:space-x-4"
                  >
                    <div className="flex-shrink-0 w-full md:w-auto text-center md:text-left">
                      <img
                        src={item.imgSrc}
                        alt={item.name}
                        className={`w-full md:w-auto h-auto object-contain max-w-[100px]  ${
                          index === 2
                            ? "ml-[-10px] md:mt-0 md:max-w-[80px]"
                            : "max-w-[100px]"
                        }`}
                      />
                    </div>
                    <div
                      className={`md:text-left mt-2 md:mt-0 w-full  ${
                        index === 2 ? "md:!ml-[45px] " : ""
                      }`}
                    >
                      <h2 className="text-[1.250rem] font-bold text-[#001D6D] mb-2 font-opensans mt-3 md:mt-0">
                        {item.name}
                      </h2>
                      <p className="text-[#001D6D] font-opensans md:text-justify">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="md:w-[55%] mt-6 md:mt-0 hidden md:block md:pl-[7%]">
                <h2 className="text-[1.250rem] font-bold text-[#001D6D] mb-6 mt-3 font-opensans ">
                  {logo?.heading}
                </h2>
                <div className="grid grid-cols-2 gap-6 md:gap-[2rem] font-opensans">
                  {[
                    Bundesministerium,
                    BadenWurttemberg,
                    LifeScienceAccelerator,
                    EITHealth,
                    MannheimBusinessSchool,
                    NextMannheim,
                  ].map((src, index) => (
                    <div key={index} className="flex items-left">
                      <img
                        src={src}
                        alt={`Logo ${index}`}
                        className="max-w-[135px] h-auto object-contain"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="text-[#2757C0] leading-tight text-4xl font-semibold  mb-3 mt-6 h-auto pt-1 pb-1 bg-[#F7F9FF]  md:px-7">
            <div className=" mx-auto mt-6 px-[4.3%] ">
              <h1 className=" text-[#2757C0] md:w-[82%]  text-4xl md:text-[3.2rem] font-semibold leading-tight mb-6 break-words  font-helvetica">
                {subItems?.title}
              </h1>
            </div>
          </div>

          <div className="container max-w-[83.60rem] mx-auto">
            {teamMembers.map((member, index) => (
              <React.Fragment key={index}>
                <div
                  className={`flex flex-col md:flex-row items-center my-8 ${
                    index % 2 === 1 ? "md:flex-row-reverse " : "md:ml-[15%]"
                  }`}
                >
                  <div
                    className={`w-full md:w-1/2 flex justify-center px-4 md:px-0 mb-4 md:mb-0 ${
                      index % 2 === 1 ? "md:justify-start" : "md:justify-start"
                    }`}
                  >
                    <img
                      src={member.imgSrc}
                      alt={member.name}
                      className="rounded-lg max-w-full md:max-w-[60%]"
                    />
                  </div>

                  <div
                    className={`w-full md:w-[49%] text-center pr-2 md:pr-20 px-4 md:px-0 ${
                      index % 2 === 1
                        ? "md:text-left md:ml-[21%]"
                        : "md:text-left"
                    }`}
                  >
                    <h2 className=" text-4xl font-semibold mb-1 text-black font-helvetica">
                      {member.name}
                    </h2>
                    <p
                      className="text-[1.50rem] font-medium mb-8 font-helvetica"
                      dangerouslySetInnerHTML={{ __html: member.title }}
                    ></p>
                    <p className="text-[1.40rem] text-[#001D6D] font-opensans text-center md:text-justify">
                      {member.specialty}
                    </p>
                    <p
                      className="text-center md:text-justify text-[1.30rem] mb-2 text-[#001D6D] font-opensans"
                      dangerouslySetInnerHTML={{ __html: member.experience }}
                    ></p>
                    <div
                      className={`flex justify-center ${
                        index % 2 === 1
                          ? "md:justify-start"
                          : "md:justify-start"
                      } px-4 md:px-0`} // Added padding for mobile view
                    >
                      {member.logos.map((logo, logoIndex) => (
                        <img
                          key={logoIndex}
                          src={logo}
                          alt={`Logo ${logoIndex + 1}`}
                          className={`${
                            index === 0
                              ? "h-12"
                              : (index === 1 && logoIndex == 0) ||
                                (index === 2 && logoIndex == 0)
                              ? "h-7"
                              : index === 2 && logoIndex == 1
                              ? "h-9 w-14"
                              : index === 1 && logoIndex == 1
                              ? "h-6"
                              : "default-height"
                          } 
        ${index === 1 ? "w-[150px] sm:w-auto" : ""} mr-3 max-w-full mt-1`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {teamMembers.length - 1 !== index && (
                  <hr
                    style={{
                      borderTop: "0.5px solid #2757C0",
                      width: "100%",
                      margin: "auto",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="text-[#2757C0] leading-tight text-4xl font-semibold  mb-3 mt-6 h-auto pt-1 pb-1.5 bg-[#F7F9FF] px-4 md:px-7">
            <div className=" mx-auto mt-6 md:px-[4.3%] ">
              <h1 className="text-[#2757C0] md:w-[90%] backedby text-4xl md:text-[3.2rem] lg:text-[3.2rem] font-semibold leading-tight mb-6 break-words font-helvetica">
                {subItems?.title2}{" "}
                <img
                  src={GermanyFlag}
                  alt="Germany Flag"
                  className="inline-block ml-2 w-6 h-6 md:w-8 md:h-8 align-middle"
                />
              </h1>
            </div>
          </div>

          <div className="containers grid md:grid-cols-2 gap-8 mt-8 mb-10 px-4 mx-auto md:px-[6%]">
            <div className="itemm md:w-[86%] w-full">
              <div className="content-box">
                <img
                  src={Technologiezentrum}
                  alt="Technologiezentrum"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <p className="text-center mt-2 text-[1.30rem] font-medium font-inter">
                MAFINEX Technologiezentrum
              </p>
            </div>
            <div className="itemm md:w-[86%] w-full">
              <div className="content-box rounded-lg">
                <iframe
                  width="100%"
                  height="100%"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?width=auto&amp;height=auto&amp;hl=en&amp;q=Julius-Hatry-Stra%C3%9Fe%201,%2068163%20Mannheim,%20Germany%20Germany+(MAFINEX%20Technologiezentrum)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  title="MAFINEX Technologiezentrum"
                  className="focus:outline-none iframe-container"
                ></iframe>
              </div>
              <p className="text-center mt-2 text-[1.30rem] font-medium font-inter">
                Julius-Hatry-Str. 1, 68163 Mannheim, Germany
              </p>
            </div>
          </div>
          <style jsx>{`
            .containers {
              display: grid;
              gap: 2rem; / Equivalent to gap-8 in Tailwind /
            }

            .itemm {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
         

            .content-box {
              width: 100%;
              overflow: hidden;
              aspect-ratio: 3 / 2; / Adjust as needed to ensure both elements have the same height /
              border-radius: 0.5rem; / Equivalent to rounded-lg /
            }

            .content-box img,
            .content-box iframe {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            @media (min-width: 768px) {
              .containers {
                grid-template-columns: repeat(2, 1fr);
              }
            }
          `}</style>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
