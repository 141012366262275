// src/components/DataProtectionPolicyData.js
const DataProtectionPolicyData = {
  heading: "Data protection at a glance ",
  sections: [
    {
      title: "General information ",
      content: `<p class="content">Second Opinion Oncology (also referred to as ‘SO’) is a web platform operated by VISION MED GMBH. This privacy policy applies to the domains (https://www.vision-med.eu) and the mobile app (‘Second Opinion Oncology’).</p>

<p class="content">Second Opinion Oncology brings patients together with medical specialists to obtain a second opinion. Second Opinion Oncology does not provide any medical services itself, but merely makes the platform available.</p>

<p class="content">This involves processing the personal data of patients and medical specialists. The following privacy policy informs the data subjects about the specific data processing.</p>

<p  class="content">The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data is all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration listed below this text.</p>
`,
    },

    {
      title: "Data collection on this website ",
      content: ``,
      subSections: [
        {
          subTitle: "Who is responsible for data collection on this website?",
          content: `
            Data processing on this website is carried out by the website operator. You can find the operator's contact details in the ‘Information on the controller’ section of this privacy policy.  `,
        },
        {
          subTitle: "How do we collect your data? ",
          content: `
            <p class="content">On the one hand, your data is collected when you provide it to us. This may, for example, be data that you enter in a contact form.</p>

<p class="content">Other data is collected automatically or with your consent by our IT systems when you visit the website. This is primarily technical data (e.g. internet browser, operating system, or time of page view). This data is collected automatically as soon as you enter this website.</p>

            `,
        },
        {
          subTitle: "What do we use your data for? ",
          content: `
    <p class="content">Some of the data is collected to ensure that the website is provided without errors.Other data may be used to analyse your user behaviour.</p>
`,
        },
        {
          subTitle: "What rights do you have regarding your data? ",
          content: `
              <p class="content">You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future.You also have the right to request the restriction of the processing of your personal data under certain circumstances. You also have the right to lodge a complaint with the competent supervisory authority. </p>

<p class="content">You can contact us at any time if you have any further questions on the subject of data protection. </p>
  `,
        },
        {
          subTitle: "Analysis tools and tools from third-party providers ",
          content: `
              <p class="content">When you visit this website, your surfing behaviour may be statistically analysed. This is primarily done using so-called analysis programmes.</p>

<p class="content">Detailed information on these analysis programmes can be found in the following privacy policy.</p>
 `,
        },
      ],
    },

 

    {
      title: "General notes and mandatory information ",
      content: ``,
      subSections: [
        {
          subTitle: "Data protection",
          content: `
              <p class="content">The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.</p>
              <p class="content">When you use this website, various personal data is collected. Personal data is data that can be used to identify you personally. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.</p>
              <p class="content">We would like to point out that data transmission over the Internet (e.g. when communicating by email) may be subject to security vulnerabilities. Complete protection of data against access by third parties is not possible.</p>
              <p class="content">Note on the responsible body</p>
              <p class="content">The controller responsible for data processing on this website is</p>
              <p class="content">VISION MED GMBH</p>
              <p class="content">Julius-Hatry-Str. 1<br />
              68163 Mannheim</p>
              <p class="content">E-mail: <a class="underline" href="mailto:support@vision-med.eu" target="_blank">support@vision-med.eu</a></p>
              <p class="content">The controller is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, email addresses, etc.).</p>
            `,
        },
        {
          subTitle: "Storage period",
          content: `
                <p class="content">Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for processing the data no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. retention periods under tax or commercial law); in the latter case, deletion will take place after these reasons no longer apply.</p>
              `,
        },
        {
          subTitle:
            "General information on the legal basis for data processing on this website",
          content: `
          <p class="content">If you have consented to data processing, we process your personal data on the basis of Art. 6 para. 1 lit. a GDPR or Art. 9 para. 2 lit. a GDPR, insofar as special categories of data are processed in accordance with Art. 9 para. 1 GDPR. In the event of express consent to the transfer of personal data to third countries, data processing is also carried out on the basis of Art. 49 para. 1 lit. a GDPR. If you have consented to the storage of cookies or access to information in your end device (e.g. via device fingerprinting), the data processing is also carried out on the basis of Section 25 (1) of the GDPR. Consent can be revoked at any time.If your data is required to fulfil a contract or to carry out pre-contractual measures, we process your data on the basis of Art. 6 para. 1 lit. b GDPR. Furthermore, we process your data if this is necessary to fulfil a legal obligation on the basis of Art. 6 para. 1 lit. c GDPR. Data processing may also be carried out on the basis of our legitimate interest in accordance with Art. 6 para. 1 lit. f GDPR. Information on the relevant legal bases in each individual case is provided in the following paragraphs of this privacy policy.</p>
        `,
        },
        {
          subTitle:
            "Note on data transfer to the USA and other third countries",
          content: `
            <p class="content">Among other things, we use tools from companies based in the USA or other third countries that are not secure under data protection law. If these tools are active, your personal data may be transferred to these third countries and processed there. We would like to point out that a level of data protection comparable to that in the EU cannot be guaranteed in these countries. For example, US companies are obliged to disclose personal data to security authorities without you as the data subject being able to take legal action against this. It can therefore not be ruled out that US authorities (e.g. secret services) may process, analyse and permanently store your data on US servers for surveillance purposes. We have no influence on these processing activities.</p>
          `,
        },
        {
          subTitle: "Withdrawal of your consent to data processing",
          content: `
            <p class="content">Many data processing operations are only possible with your express consent. You can withdraw your consent at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>
          `,
        },
        {
          subTitle:
            "Right to object to data collection in special cases and to direct advertising (Art. 21 GDPR)",
          content: `
            <p class="content">IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME ON GROUNDS RELATING TO YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA CONCERNED UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS OR THE PROCESSING SERVES THE ESTABLISHMENT, EXERCISE OR DEFENCE OF LEGAL CLAIMS (OBJECTION PURSUANT TO ART. 21 PARA. 1 GDPR).</p>
            <p class="content">IF YOUR PERSONAL DATA ARE PROCESSED FOR THE PURPOSE OF DIRECT MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING; THIS ALSO APPLIES TO PROFILING INSOFAR AS IT IS RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO ART. 21 PARA. 2 GDPR).</p>
          `,
        },
        {
          subTitle:
            "Right to lodge a complaint with the competent supervisory authority",
          content: `
            <p class="content">In the event of infringements of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, place of work or place of the alleged infringement. The right to lodge a complaint is without prejudice to any other administrative or judicial remedy.</p>
          `,
        },
        {
          subTitle: "Right to data portability",
          content: `
            <p class="content">You have the right to have data that we process automatically on the basis of your consent or in fulfilment of a contract handed over to you or to a third party in a commonly used, machine-readable format. If you request the direct transfer of the data to another controller, this will only take place if it is technically feasible.</p>
          `,
        },
        {
          subTitle: "Information, erasure and rectification",
          content: `
            <p class="content">Within the framework of the applicable legal provisions, you have the right to free information about your stored personal data, its origin and recipients and the purpose of the data processing and, if necessary, a right to correction or deletion of this data at any time. You can contact us at any time regarding this and other questions on the subject of personal data.</p>
          `,
        },
        {
          subTitle: "Right to restriction of processing",
          content: `
            <p class="content">You have the right to request the restriction of the processing of your personal data. You can contact us at any time to do this. The right to restriction of processing exists in the following cases</p>
            <ul class="unordered sub-list">
              <li>If you dispute the accuracy of your personal data stored by us, we generally need time to verify this. For the duration of the review, you have the right to request that the processing of your personal data be restricted.</li>
              <li>If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of erasure.</li>
              <li>If we no longer need your personal data, but you need it for the exercise, defence or assertion of legal claims, you have the right to request the restriction of the processing of your personal data instead of its erasure.</li>
              <li>If you have lodged an objection in accordance with Art. 21 para. 1 GDPR, a balance must be struck between your interests and ours. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.</li>
            </ul>
            <p class="content">If you have restricted the processing of your personal data, this data - apart from its storage - may only be processed with your consent or for the establishment, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or of a Member State.</p>
          `,
        },
        {
          subTitle: "SSL or TLS encryption",
          content: `
            <p class="content">This site uses SSL or TLS encryption for security reasons and to protect the transmission of confidential content, such as orders or enquiries that you send to us as the site operator. You can recognise an encrypted connection by the fact that the address line of the browser changes from ‘http://’ to ‘https://’ and by the lock symbol in your browser line.</p>
            <p class="content">If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.</p>
          `,
        },
        {
          subTitle: "Objection to advertising emails",
          content: `
            <p class="content">We hereby object to the use of contact data published as part of our obligation to provide a legal notice for the purpose of sending unsolicited advertising and information material. The operators of this website expressly reserve the right to take legal action in the event of the unsolicited sending of advertising information, such as spam e-mails.</p>
          `,
        },
      ],
    },

    {
      title: "Data collection on this website",
      subSections: [
        {
          subTitle: "Registration as a patient",
          content: `
              <p class="content">In order to use our platform, the patient must register (create a user account) and provide the following personal data</p>
              <ul class="unordered sub-list">
                <li>E-mail address</li>
                <li>Surname</li>
                <li>First name</li>
                <li>Date of birth</li>
              </ul>
              <p class="content">We will send an e-mail with a personalised activation link (double opt-in) to the e-mail address. This is to prevent misuse during registration. The user contract with the patient is only concluded after confirmation of the link sent by e-mail.</p>
            `,
        },
        {
          subTitle: "Registration as a doctor",
          content: `
              <p class="content">In order to use our platform as a doctor, we require the following personal data from the doctor:</p>
              <ul class="unordered sub-list">
                <li>Personal master data (surname, first name, date of birth, address data of the practice location)</li>
                <p class="content">We need this data for identification, checking the requirements for using our service, making contact and billing.</p>
                <li>Data on licence to practise and specialist recognition</li>
                <p class="content">We need this data to check the requirements for using our services.</p>
                <li>E-mail address</li>
                <p class="content">We need the e-mail address to contact the doctor after registration in order to subsequently request further data to check the requirements and finalise the registration.</p>
                <li>Curriculum vitae</li>
                <p class="content">The legal basis for the processing of this data is the fulfilment of the user contract in accordance with Art. 6 para. 1b GDPR. It is not possible to use our service without this data. We delete this data as soon as the purpose has been fulfilled (e.g. if the doctor deletes their account) and there are no legal retention periods to the contrary.</p>
              </ul>
            `,
        },
        {
          subTitle: "Appointment booking",
          content: `
    <p class="content">When booking an appointment for a medical consultation, the patient is asked to provide the following data</p>
    <ul class="unordered sub-list">
      <li>Telephone number</li>
      <li>Current complaints</li>
      <li>Free text for message to the doctor</li>
    </ul>
    <p class="content">The data is processed for identification, communication and forwarding to the doctor.</p>
    <p class="content">The processing of this data is necessary in order to fulfil the concluded user contract. The legal basis for this is Art. 6 para. 1b GDPR and Art. 9 para. 2a GDPR.</p>
    <p class="content">In addition to confirming registration, we also use the patient's email address for contract-related communication (e.g. queries, appointment reminders).</p>
  `,
        },
        {
          subTitle: "Mandatory Information",
          content: `
              <p class="content">All fields with mandatory information are marked with an asterisk (‘*’) on our platform. It is not possible to use the corresponding function without this information.</p>
            `,
        },
        {
          subTitle: "Storage Period",
          content: `
              <p class="content">If no specific storage period or criteria for determining the period are specified in this data protection notice, the following applies:</p>
              <p class="content">The storage period for personal data is measured on the basis of the specific purposes for which the data is used. In addition, SO or the doctors are subject to statutory retention and documentation obligations, which arise in particular from the German Commercial Code (HGB), the German Fiscal Code (AO) and the Medical Professional Code and often amount to six or ten years. Finally, the storage period is also determined by the statutory limitation periods, which are set out, for example, in Sections 195 et seq. of the German Civil Code (BGB).</p>
            `,
        },
        {
          subTitle: "Cookies",
          content: `
              <p class="content">Our Internet pages use so-called ‘cookies’. Cookies are small text files and do not cause any damage to your end device. They are stored on your device either temporarily for the duration of a session (session cookies) or permanently (permanent cookies). Session cookies are automatically deleted at the end of your visit. Permanent cookies remain stored on your end device until you delete them yourself or they are automatically deleted by your web browser.</p>
              <p class="content">In some cases, cookies from third-party companies may also be stored on your device when you visit our website (third-party cookies). These enable us or you to use certain services of the third-party company (e.g. cookies for processing payment services).</p>
              <p class="content">Cookies have various functions. Many cookies are technically necessary, as certain website functions would not work without them (e.g. the shopping basket function or the display of videos). Other cookies are used to evaluate user behaviour or display advertising.</p>
              <p class="content">Cookies that are required to carry out the electronic communication process, to provide certain functions that you have requested (e.g. for the shopping basket function) or to optimise the website (e.g. cookies to measure the web audience) (necessary cookies) are stored on the basis of Art. 6 para. 1 lit. f GDPR, unless another legal basis is specified. The website operator has a legitimate interest in the storage of necessary cookies for the technically error-free and optimised provision of its services. If consent to the storage of cookies and comparable recognition technologies has been requested, the processing is carried out exclusively on the basis of this consent (Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG); the consent can be revoked at any time.</p>
              <p class="content">You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this website may be restricted.</p>
              <p class="content">If cookies are used by third-party companies or for analysis purposes, we will inform you about this separately in this privacy policy and, if necessary, request your consent.</p>
            `,
        },
        {
          subTitle: "Server Log Files",
          content: `
              <p class="content">The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are</p>
              <ul class="unordered sub-list">
                <li>Browser type and browser version</li>
                <li>Operating system used</li>
                <li>Referrer URL</li>
                <li>Host name of the accessing computer</li>
                <li>Time of the server enquiry</li>
                <li>IP address</li>
              </ul>
              <p class="content">This data is not merged with other data sources.</p>
              <p class="content">This data is collected on the basis of Art. 6 para. 1 lit. f GDPR. The website operator has a legitimate interest in the technically error-free presentation and optimisation of its website - the server log files must be recorded for this purpose.</p>
            `,
        },
        {
          subTitle: "Contact Form",
          content: `
              <p class="content">If you send us enquiries via the contact form, your details from the enquiry form, including the contact data you provide there, will be stored by us for the purpose of processing the enquiry and in the event of follow-up questions. We will not pass on this data without your consent.</p>
              <p class="content">This data is processed on the basis of Art. 6 para. 1 lit. b GDPR if your enquiry is related to the fulfilment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the enquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; consent can be revoked at any time.</p>
              <p class="content">We will retain the data you provide on the contact form until you request its deletion, revoke your consent for its storage, or the purpose for its storage no longer pertains (e.g. after fulfilling your request). Mandatory statutory provisions - in particular retention periods - remain unaffected.</p>
            `,
        },
        {
          subTitle: "Enquiry by E-mail, Telephone or Fax",
          content: `
              <p class="content">If you contact us by e-mail, your enquiry including all personal data (name, enquiry) will be stored and processed by us for the purpose of processing your request. We will not pass on this data without your consent.</p>
              <p class="content">This data is processed on the basis of Art. 6 para. 1 lit. b GDPR if your enquiry is related to the fulfilment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the enquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; consent can be revoked at any time.</p>
              <p class="content">The data you send to us via contact requests will remain with us until you ask us to delete it, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.</p>
            `,
        },
      ],
    },
    {
      title: "Newsletter",
      content: "",
      subSections: [
        {
          subTitle: "Newsletter Data",
          content: `
              <p class="content">If you would like to receive the newsletter offered on the website, we require an e-mail address from you as well as information that allows us to verify that you are the owner of the e-mail address provided and that you agree to receive the newsletter. No further data is collected, or only on a voluntary basis. We use this data exclusively for sending the requested information and do not pass it on to third parties.</p>
              
              <p class="content">The data entered in the newsletter registration form is processed exclusively on the basis of your consent (Art. 6 para. 1 lit. a GDPR). You can revoke your consent to the storage of the data, the e-mail address and its use for sending the newsletter at any time, for example via the ‘unsubscribe’ link in the newsletter. The legality of the data processing operations that have already taken place remains unaffected by the cancellation.</p>
              
              <p class="content">The data you provide us with for the purpose of subscribing to the newsletter will be stored by us or the newsletter service provider until you unsubscribe from the newsletter and deleted from the newsletter distribution list after you unsubscribe from the newsletter or after the purpose has ceased to exist. We reserve the right to delete or block e-mail addresses from our newsletter distribution list at our own discretion within the scope of our legitimate interest in accordance with Art. 6 para. 1 lit. f GDPR.</p>
              
              <p class="content">Data stored by us for other purposes remains unaffected by this.</p>
              
              <p class="content">After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the newsletter service provider in a blacklist if this is necessary to prevent future mailings. The data from the blacklist will only be used for this purpose and will not be merged with other data. This serves both your interest and our interest in complying with the legal requirements when sending newsletters (legitimate interest within the meaning of Art. 6 para. 1 lit. f GDPR). <strong>Storage in the blacklist is not limited in time. You can object to the storage if your interests outweigh our legitimate interest.</strong></p>
            `,
        },
      ],
    },

    {
      title: "Plugins and tools",
      content: "",
      subSections: [
    
        {
          subTitle: "YouTube with Extended Data Protection",
          content: `
              <p class="content">This website embeds videos from YouTube. The operator of the pages is Google Ireland Limited (‘Google’), Gordon House, Barrow Street, Dublin 4, Ireland.</p>
              
              <p class="content">We use YouTube in extended data protection mode. According to YouTube, this mode means that YouTube does not store any information about visitors to this website before they watch the video. However, the transfer of data to YouTube partners is not necessarily excluded by the extended data protection mode. For example, YouTube establishes a connection to the Google DoubleClick network regardless of whether you watch a video.</p>
              
              <p class="content">As soon as you start a YouTube video on this website, a connection to the YouTube servers is established. The YouTube server is informed which of our pages you have visited. If you are logged into your YouTube account, you enable YouTube to assign your surfing behaviour directly to your personal profile. You can prevent this by logging out of your YouTube account.</p>
              
              <p class="content">Furthermore, YouTube can store various cookies on your end device after starting a video or use comparable recognition technologies (e.g. device fingerprinting). In this way, YouTube can obtain information about visitors to this website. This information is used, among other things, to record video statistics, improve user-friendliness and prevent fraud attempts.</p>
              
              <p class="content">If necessary, further data processing operations may be triggered after the start of a YouTube video, over which we have no influence.</p>
              
              <p class="content">The use of YouTube is in the interest of an appealing presentation of our online offers. This constitutes a legitimate interest within the meaning of Art. 6 para. 1 lit. f GDPR. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. Consent can be revoked at any time.</p>
              
              <p class="content">Further information about data protection at YouTube can be found in their privacy policy at: <a class="underline" href="https://policies.google.com/privacy?hl=de" target="_blank" class="underline">https://policies.google.com/privacy?hl=de</a>.</p>
            `,
        },


        {
          subTitle: "Google Maps",
          content: `
            <p class="content">This site uses the Google Maps map service via an API. The provider is Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA.</p>
            
            <p class="content">Your IP address is saved and transferred to a Google server in the USA and stored there to use the map service. The provider of this page does not influence this data transmission. The use of Google Maps is in the interest of an appealing presentation of our online offers and to make it easier to find the places we have indicated on the website. This represents a legitimate interest within the meaning of Art. 6 Para. 1 lit. f GDPR. Please find more information on the handling of user data in Google's privacy policy at <a class="underline" href="https://www.google.de/intl/de/policies/privacy/" target="_blank">https://www.google.de/intl/de/policies/privacy/</a>.</p>
          `,
        },
        {
          subTitle: "Google Analytics",
          content: `
          <p class="content">We use Google Analytics to understand the usage of our service and improve the user experience. For more information on how Google Analytics collects and processes data,please visit <a class="underline" href="https://policies.google.com/technologies/partner-sites" target="_blank" class="underline">https://policies.google.com/technologies/partner-sites</a>.</p>
          
          `,
        }
,        
 









        {
          subTitle: "Google Web Fonts",
          content: `
                <p class="content">This site uses so-called web fonts provided by Google for the standardized display of fonts. When you call up a page, your browser loads the required web fonts into your browser cache in order to display texts and fonts correctly.</p>
                
                <p class="content">For this purpose, the browser you are using must connect to Google's servers. As a result, Google becomes aware that this website has been accessed via your IP address. The use of Google WebFonts is based on Art. 6 para. 1 lit. f GDPR. The website operator has a legitimate interest in the uniform presentation of the typeface on its website. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. Consent can be revoked at any time.</p>
                
                <p class="content">If your browser does not support web fonts, a standard font will be used by your computer.</p>
                
                <p class="content">Further information on Google Web Fonts can be found at <a class="underline" href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a> and in Google's privacy policy <a class="underline" href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a>.</p>
              `,
        },
        {
          subTitle: "Font Awesome",
          content: `
                <p class="content">This site uses Font Awesome for the standardized display of fonts and symbols. The provider is Fonticons, Inc, 6 Porter Road Apartment 3R, Cambridge, Massachusetts, USA.</p>
                
                <p class="content">When you call up a page, your browser loads the required fonts into your browser cache in order to display texts, fonts and symbols correctly. For this purpose, the browser you are using must connect to Font Awesome's servers. As a result, Font Awesome becomes aware that this website has been accessed via your IP address. The use of Font Awesome is based on Art. 6 para. 1 lit. f GDPR. We have a legitimate interest in the uniform presentation of the typeface on our website. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. Consent can be revoked at any time.</p>
                
                <p class="content">If your browser does not support Font Awesome, a standard font will be used by your computer.</p>
                
                <p class="content">Further information about Font Awesome can be found in Font Awesome's privacy policy at: <a class="underline" href="https://fontawesome.com/privacy" class="underline" target="_blank">https://fontawesome.com/privacy</a>.
                <p >Source:<a class="underline" href="https://www.e-recht24.de" class="underline" target="_blank"> https://www.e-recht24.de </a> </p>
              `,
        },
      ],
    },
  ],
};

export default DataProtectionPolicyData;
