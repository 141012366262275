import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import dataProtectionContent from './DataProtectionPolicyData';
import Navbar from '../../Pages/NavBar';
import Footer from '../../Pages/Footer';
import useScrollToTop from '../../config/useScrollToTop';

const DataProtectionPolicy = () => {
  useScrollToTop();
  const { heading, sections } = dataProtectionContent;
  const [currentSection, setCurrentSection] = useState(null);
  const [currentSubSection, setCurrentSubSection] = useState('');
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
  

    // Set currentSection based on whether the device is mobile
    if (isMobile) {
      setCurrentSection('');
    } else {
      setCurrentSection(sections[0].title);
    }
  }, [isMobile]);
  const handleSectionClick = (title, subTitle = '', e) => {
    if (e) e.preventDefault();
    setCurrentSection(title);
    setCurrentSubSection(subTitle);
    const sectionElement = document.getElementById(subTitle ? subTitle.replace(/ /g, '-') : title.replace(/ /g, '-'));
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if(!isMobile){
    let found = false;
    sections.forEach((section) => {
      if (found) return;
      const sectionElement = document.getElementById(section.title.replace(/ /g, '-'));
      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          setCurrentSection(section.title);
          setCurrentSubSection('');
          found = true;
          return;
        }
      }
      if (section.subSections) {
        section.subSections.forEach((subSection) => {
          if (found) return;
          const subSectionElement = document.getElementById(subSection.subTitle.replace(/ /g, '-'));
          if (subSectionElement) {
            const rect = subSectionElement.getBoundingClientRect();
            if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
              setCurrentSection(section.title);
              setCurrentSubSection(subSection.subTitle);
              found = true;
              return;
            }
          }
        });
      }
    });
  }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(prevState => !prevState);
  };

  return (
    <>
      <Navbar />
            {/* Mobile Navigation */}
            {isMobile && (
            <div className="md:hidden w-full mb-2">
              <div className="bg-white px-3 ">
                <button
                  className="flex justify-between items-center w-full text-lg font-semibold text-[#0A1E54]"
                  onClick={toggleMobileNav}
                >
                  <span>Contents</span>
                  {isMobileNavOpen ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {isMobileNavOpen && (
                  <ul className="mt-6 space-y-2">
                    {sections.map((section, index) => (
                      <li key={index}>
                        <a
                          href={`#${section.title.replace(/ /g, '-')}`}
                          className={`block text-lg font-semibold cursor-pointer ${currentSection === section.title && !currentSubSection ? 'text-[#2757C0]' : 'text-[#0A1E54]'}`}
                          onClick={(e) => handleSectionClick(section.title, '', e)}
                        >
                          {section.title}
                        </a>
                        {section.subSections && (
                          <ul className="ml-4">
                            {section.subSections.map((subSection, subIndex) => (
                              <li key={subIndex}>
                                <a
                                  href={`#${subSection.subTitle.replace(/ /g, '-')}`}
                                  className={`block text-lg font-semibold cursor-pointer mt-3 ${currentSubSection === subSection.subTitle ? 'text-[#2757C0]' : 'text-[#0A1E54]'}`}
                                  onClick={(e) => handleSectionClick(section.title, subSection.subTitle, e)}
                                >
                                  {subSection.subTitle}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                  
              </div>
            </div>
          )}
            <hr
          className='md:hidden'
          style={{
            borderTop: "1px solid rgba(42, 96, 214, 1)",
            width: "100%",
            margin: "auto",
          }}
        />
      <div className="bg-[#F7F9FF] md:py-16 pt-4 md:pt-[5rem]">
        <div className={` ${isMobile ? 'bg-white' : ''}`}>
          {/* Mobile heading with background color */}
          <div className={`w-full md:mt-8 mt-3 ${isMobile ? 'bg-[#F7F9FF] p-3' : ''} md:hidden`}>
            <h1 className="text-[#2757C0] md:text-[3.2rem] text-4xl font-semibold md:mb-16 mb-8 px-1 md:mt-12">
              {heading}
            </h1>
          </div>

    

          <h1 className={`hidden md:block text-[#2757C0] text-4xl md:text-[3.2rem]  font-semibold mb-16 md:px-[5.860%] font-helvetica`}>{heading}</h1>
          <div className="flex justify-between items-center mb-4 ml-14"></div>
          <div className={`flex ${isMobile ? 'flex-col' : 'md:pl-[5.860%] md:pr-[6%] flex-row'} pl-4 pr-5 gap-8`}>
            {!isMobile && (
              <div className={`w-full md:w-[63%]  mb-4`} style={{ position: 'sticky', top: '70px', alignSelf: 'flex-start' }}>
                <nav className="mt-[15%] max-h-[65vh] overflow-y-auto pr-2 custom-scrollbar">
                  <div className="w-full flex items-center justify-between">
                    <h2 className="text-[#0A1E54] font-semibold text-[26px] leading-[48px] tracking-[-0.30px] font-helvetica mb-4 flex-grow flex-shrink-0 whitespace-nowrap">
                      Data Protection Policy
                      <button 
                        onClick={() => setIsNavVisible(!isNavVisible)} 
                        className="text-[#0A1E54] pl-1 text-2xl focus:outline-none flex-shrink-0"
                      >
                        {isNavVisible ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                    </h2>
                  </div>

                  {isNavVisible && (
                    <ul className="text-[#2757C0]">
                      {sections.map((section, index) => (
                        <li key={index} className="mb-5 font-helvetica">
                          <strong>
                            <a
                              href={`#${section.title.replace(/ /g, '-')}`}
                              className={`block md:text-justify text-[1.10rem] font-helvetica ${currentSection === section.title && !currentSubSection ? 'text-[#2757C0] font-semibold mb-4' : 'font-semibold text-[#0A1E54] mb-4'}`}
                              onClick={(e) => handleSectionClick(section.title, '', e)}
                            >
                              {section.title}
                            </a>
                          </strong>
                          {section.subSections && (
                            <ul className="ml-4">
                              {section.subSections.map((subSection, subIndex) => (
                                <li key={subIndex} className="mb-2">
                                  <strong>
                                    <a
                                      href={`#${subSection.subTitle.replace(/ /g, '-')}`}
                                      className={`text-justify block font-helvetica  text-[1.10rem] ${currentSubSection === subSection.subTitle ? 'text-[#2757C0] font-semibold' : 'font-semibold text-[#0A1E54]'}`}
                                      onClick={(e) => handleSectionClick(section.title, subSection.subTitle, e)}
                                    >
                                      {subSection.subTitle}
                                    </a>
                                  </strong>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </nav>
              </div>
            )}
            <div className={`w-full  ${isMobile ? 'bg-white' : 'ml-[5%]'}`}>
              {sections.map((section, index) => (
                <div key={index} className="mb-16">
                  <h2 id={section.title.replace(/ /g, '-')} className="md:text-justify text-[2rem] font-helvetica font-semibold text-[#2757C0] mb-4">
                    {section.title}
                  </h2>
                  {section.content && <p className="text-lg md:text-justify text-[#0A1E54] mb-3 font-inter" dangerouslySetInnerHTML={{ __html: section.content }}></p>}
                  {section.subSections && section.subSections.map((subSection, subIndex) => (
                    <div key={subIndex} className="mb-10">
                      <h3 id={subSection.subTitle.replace(/ /g, '-')} className="md:text-justify text-2xl mt-8 font-helvetica font-semibold px-0 text-[#2757C0] mb-2">
                        {subSection.subTitle}
                      </h3>
                      
                      <p className="text-lg text-[#0A1E54] mb-4 font-inter md:text-justify" dangerouslySetInnerHTML={{ __html: subSection.content }}></p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <style jsx>{`
          p .content {
            margin-top: 10px;
          }

          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #00103C #F7F9FF;
          }
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
          }
          .underline {
            text-decoration: underline;
          }
          .underline {
            word-wrap: break-word; /* Older syntax */
            overflow-wrap: break-word; /* Modern and recommended syntax */
            word-break: break-all; /* Ensures breaking of long strings like URLs */
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(0, 16, 60, 0.7); /* Dark blue with slight transparency */
            border-radius: 60px !important;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #F7F9FF; /* Light background color */
          }
          .unordered {
            list-style-type: none;
            padding-left: 0;
            margin-top: 14px;
          }
          .unordered li {
            position: relative;
            padding-left: 1.5rem; /* Adjust spacing between bullet and text */
            margin-bottom: 0.05rem; /* Adjust spacing between items */
            margin-top: 14px; /* Adjust top margin */
          }

          .unordered li::before {
            content: '';
            position: absolute;
            top: 0.75rem; /* Adjust vertical alignment */
            left: 0;
            width: 0.4rem; /* Adjust size of bullet */
            height: 0.4rem; /* Adjust size of bullet */
            background-color: #0A1E54; /* Set bullet color to red */
            border-radius: 50%; /* Make the bullet a circle */
          }

          .sub-list ul {
            margin-left: 2rem;
          }
          .sub-list li {
            margin-left: 1rem;
            font-size: 1.125rem;
            color: #0A1E54; 
            margin-bottom: 1rem;
            font-family: 'Inter', sans-serif;
            
          }

          .orderred {
            list-style-type: decimal;
            margin-left: 20px;
            margin-bottom: 20px;
            margin-top: 14px;
          }
          .textstyling {
            font-weight: 500 !important;
            margin-top: 10px !important;
          }
          @media (max-width: 768px) {
            .sub-list ul {
              margin-left: 0rem;
              margin-top: 4px;
            }
            .underline {
              word-wrap: break-word; /* Older syntax */
              overflow-wrap: break-word; /* Modern and recommended syntax */
              word-break: break-all; /* Ensures breaking of long strings like URLs */
            }
            p .content {
              margin-top: 13px;
            }
            .sub-list li {
              margin-left: 3px;
              margin-top: 4px;
              color: #0A1E54 !important;
              font-size: 1.125rem;
              margin-bottom: 1rem; 
              font-family: 'Inter', sans-serif; 
               
            }
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};

export default DataProtectionPolicy;
